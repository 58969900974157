import React, { useEffect } from "react";
import footerbg from "../assets/refund.png";
import Footer from "./Footer";
import bRefund from "../assets/b-refund.png";
import sRefund from "../assets/refund.png";

const Refundpolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-full bg-[#F6F6F6] ">
      <div className="privacy-container pb-14">
        <img src={sRefund} alt="" className="w-full md:hidden" />
        <img src={bRefund} alt="" className="w-full hidden md:block" />

        <div className="px-4 md:px-32 py-4 md:py-8 font-raleway">
          <div className="bg-gray-100">
            <p className="mb-6 font-bold text-[18px] md:text-[24px] tracking-wider">
              Effective Date: 21-08-2023
            </p>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              This Refund & Return Policy ("Policy") outlines the terms and
              conditions for refunds and returns related to ticket bookings for
              events organized by Flo Design ("we," "our," or "us"). By booking
              tickets for our events, you agree to adhere to the refund and
              return policy stated below. If you do not agree with this policy,
              please do not proceed with ticket bookings.
            </p>

            <h2 className="mb-2 font-bold text-[18px] md:text-[24px] tracking-wider">
              1. Refund Policy
            </h2>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              a. Once a ticket booking for an event is confirmed and payment is
              made, refunds will not be issued under any circumstances. This
              includes but is not limited to cancellations, changes in schedule,
              participant preferences, or any other reasons.
            </p>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              b. We do not entertain refund requests for any events, whether
              they are online or offline, once the booking process is completed.
            </p>

            <h2 className="mb-2 font-bold text-[18px] md:text-[24px] tracking-wider">
              2. Return Policy
            </h2>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              a. Due to the nature of our events and services, return of tickets
              is not applicable. Once a ticket booking is confirmed, it is
              considered non-returnable.
            </p>

            <h2 className="mb-2 font-bold text-[18px] md:text-[24px] tracking-wider">
              3. Exclusions
            </h2>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              a. This policy applies solely to ticket bookings for events
              organized by Flo Design and does not extend to any other products
              or services that may be offered by us.
            </p>

            <h2 className="mb-2 font-bold text-[18px] md:text-[24px] tracking-wider">4. Contact Us</h2>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              If you have any questions, concerns, or inquiries related to our
              refund and return policy, please contact us at the following:
            </p>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">Flo Design</p>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              Address: 8-2-465, Flat No.101, Studio Sycamore, Road No.4, Opp
              Union Bank of India, Banjara Hills, Hyderabad 500034
            </p>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">Email: info@flodesign.in</p>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">Phone: +91 81060 70259</p>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              By booking tickets for our events, you acknowledge that you have
              read, understood, and agreed to abide by this Refund & Return
              Policy.
            </p>

            <p className="text-[16px] md:text-[22px] text-gray-500 mt-4 tracking-wider">
              Last Updated: 21-08-2023
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Refundpolicy;
