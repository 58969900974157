import { object, string, number, date, InferType } from 'yup';

const contactSchema = object({
  name: string().required("Name field is required !"),
  mobile: string().required("Mobile field is required !"),
  email: string().email().required("Email field is required !"),
  request:string().required("Request type is required !") ,
  inbox_content: string().required("Inbox content field is required !")
});



export default contactSchema
