import React from "react";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import About from "./components/About";
import PaymentSuccessCard from "./components/PaymentSuccessCard";

import PaymentFailureCard from "./components/PaymentFailureCard";

import { Routes, Route } from "react-router-dom";
import Shop from "./components/Shop";
import Event from "./components/Event";
import "./components/style.css";
import Booking from "./components/Booking";
import Privacypolicy from "./components/Privacypolicy";
import Termandcondition from "./components/Termandcondition";
import Refundpolicy from "./components/Refundpolicy";
import Cancellation from "./components/Cancellation";
import Shopping from "./components/Shopping";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/Footer";

import { useSelector, useDispatch } from "react-redux";
import Loader from "./components/Loader";
import NewShop from "./components/NewShop";

const App = () => {
  const isLoading = useSelector((state) => state.indexReducer.loading);
  return (
    <div className='' style={{ backgroundColor: "#F6F6F6" }}>
      <>
        <Navbar />

        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contacthome' element={<Home />} />
          <Route path='/aboutus' exact element={<About />} />
          <Route path='/events' exact element={<Event />} />
          {/* <Route path='/shop' exact element={<Shop />} /> */}
          <Route path='/shop' exact element={<NewShop />} />
          <Route path='/contactus' exact element={<Footer />} />
          <Route path='/bookingpage/:slugids' exact element={<Booking />} />
          <Route path='/shoppingpage' exact element={<Shopping />} />
          <Route path='/privacypolicy' exact element={<Privacypolicy />} />
          <Route path='/term&condition' exact element={<Termandcondition />} />
          <Route path='/refundpolicy' exact element={<Refundpolicy />} />
          <Route path='/cancellationpolicy' exact element={<Cancellation />} />

          <Route
            path='/transaction/success/:orderid'
            exact
            element={<PaymentSuccessCard />}
          />
          <Route
            path='/transaction/failure/:orderid'
            exact
            element={<PaymentFailureCard />}
          />
        </Routes>
        <Footer />
        <ToastContainer />
      </>

      {/* {!isLoading ? <Loader /> : ""} */}
    </div>
  );
};




export default App;
