import React, { useEffect } from 'react'
import sCancellation from '../assets/cancellation.png'
import Footer from './Footer'
import bCancellation from '../assets/b-cancellation.png'


const Cancellation = () => {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div className='w-full bg-[#F6F6F6] '>

            <div className='privacy-container pb-14'>

                <img src={sCancellation} alt="" className='w-full md:hidden' />
                <img src={bCancellation} alt="" className='w-full hidden md:block' />


            </div>

           
        </div>
    )
}

export default Cancellation