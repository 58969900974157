import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import trophy from "../assets/award.png";
import horlicksWizard from "../assets/horlicksWizard.svg";
import limcaBook from "../assets/limcaBook.svg";
import medal from "../assets/medal.svg";
import artExhibition from "../assets/artExhibition.svg";
// import Select, { Option } from 'rc-select';
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
// import { Select, Option } from "@material-tailwind/react";
import { Link } from "react-scroll";
import { FiChevronDown } from "react-icons/fi";
import mobilebgimg from "../assets/flo-design-bg-1.png";
import desktopbg from "../assets/desktop-bg-1.png";
import bgvideo from "../assets/bg-video.mp4";
import aboutimg from "../assets/aboutimg.png";
import profileImg from "../assets/clientImg.png";
import desktopaboutimg from "../assets/deskktop-about.png";
import barcode from "../assets/barcode.png";
// import { DefaultPlayer as Video } from 'react-html5video';
import "react-html5video/dist/styles.css";
import { useQuery } from "react-query";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import cardimg from "../assets/card-1.png";
// import required modules
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import Card from "./Card";
import Footer from "./Footer";

import desktoplogo from "../assets/desktoplogo.png";
import EventCard from "./cards/EventCard";
import UpcomingCard from "./cards/UpcomingCard";

import { useFormik, Field } from "formik";
import contactSchema from "../schema/contactus";
import { APIUrls } from "../api/APIclient";

import { useNavigate } from "react-router-dom";
import moment from "moment";

const EVENTS_LINKS = [
  "https://pages.razorpay.com/pl_PWd7roF9ua6d18/view",
  "https://pages.razorpay.com/pl_PV5pCByRC9bEX7/view",
  "https://pages.razorpay.com/pl_PV5zX0MkM4Rmmj/view"
];

const Home = (props) => {
  const navigate = useNavigate();
  let message;
  const notifyA = () => toast.success(message);
  const notifyB = () => toast.error(message);

  function verifyEmail(email) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }

  function verifyIndianPhoneNumber(phoneNumber) {
    const phoneRegex = /^(\+91|0)?[6789]\d{9}$/;
    return phoneRegex.test(phoneNumber);
  }

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [messages, setMessages] = useState("");
  const [loader, setLoader] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  console.log("home page loaded");

  const { values, handleChange, handleSubmit, errors, touched, resetForm } =
    useFormik({
      initialValues: {
        name: "",
        mobile: "",
        email: "",
        request: "",
        inbox_content: "",
      },

      validationSchema: contactSchema,

      onSubmit: async (values) => {
        if (_.isEmpty(recaptchaValue)) {
          message = "Please enable the reCaptcha !!";
          return notifyB(message);
        }
        await handleTicket(values);
        resetForm();
      },
    });

  const location = useLocation();

  const { isError, refetch, data, error, status, isLoading } = useQuery({
    queryKey: ["benificers"],
    queryFn: async () => {
      try {
        const response = await fetch(APIUrls.fetchEvents, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const responseJson = await response.json();

        console.log(responseJson);
        if (_.isEmpty(responseJson)) {
          return;
        }

        if (responseJson?.code === 200) {
          if (responseJson.response.eventCount !== 0) {
            const benarray = responseJson.response.eventss;

            return benarray;
          }

          const message = responseJson.response.message;
        }

        // if (responseJson.response.beneficer_count == 0) {
        //   throw new Error("There are no benificers available");
        // }
        // if (responseJson?.code === 492) {
        //   const message = responseJson.response.message;
        //   throw new Error("There is no benificary");
        // }

        if (responseJson?.code === 400) {
          const message = responseJson.response.message;
          throw new Error("Something went Wrong ,Try again later !");
        }

        console.log(responseJson);
      } catch (error) {
        console.log("error", error);
        throw new Error(error.message);
      }
    },
  });

  const [request, setRequest] = useState("");

  const options = [
    {
      label: "Complain",
      value: "Complain",
      id: 1,
    },
    {
      label: "Request",
      value: "Request",
      id: 2,
    },
    {
      label: "Order",
      value: "Order",
      id: 3,
    },
    {
      label: "Events",
      value: "Events",
      id: 4,
    },
  ];

  const selectRequestType = (e) => {
    console.log("request is selected");

    console.log(e);

    const syntheticEvent = { target: { value: e.value } };

    console.log(syntheticEvent);
    handleChange(syntheticEvent);
  };

  useEffect(() => {
    var element = document.getElementById("contact");
    var elementRect = element.getBoundingClientRect();

    const scrollToTop = elementRect.top - 100 + window.scrollY;
    console.log(elementRect);

    console.log(location);

    if (location.pathname === "/contacthome") {
      window.scrollTo(elementRect.left, scrollToTop);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const handleTicket = async (data) => {
    setLoader(true);

    try {
      const result = await axios.post(APIUrls.contactUs, {
        name: data.name,
        email: data.email,
        phone: data.mobile,
        type: data.request,
        messages: data.inbox_content,
      });

      if (
        result.status === 400 ||
        result.status === 404 ||
        result.status === 409 ||
        result.status === 500
      ) {
        setLoader(false);
        message = "Oops! Request failed to send to generate new ticket";
        return notifyB(message);
      }

      if (result.status === 201) {
        if (_.isEmpty(result)) {
          setLoader(false);
          message = "Oops! Something went wrong";
          return notifyB(message);
        }

        setLoader(false);
        setName("");
        setEmail("");
        setPhone("");
        setType("");
        setMessages("");
        message =
          "Great! Your ticket generated successfully you will get reply shortly on your email.";
        return notifyA(message);
      }
    } catch (error) {
      setLoader(false);
      message = "Oops! Something went wrong while generating new ticket.";
      return notifyB(message);
    }
  };

  return (
    <div className="home-container">
      {/* =====================    section-1       ================================== */}
      <div className="home-content mb-5">
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          autoplay={{
            autoPlay: true,
            delay: 3000, // Set the delay in milliseconds between slides
            disableOnInteraction: false, // Continue autoplay even when the user interacts with the slider
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
          className="mySwiper"
        >
          {/* <SwiperSlide className="">
            <div className="video ">
              <video
                className="h-[220px] md:hidden sm:hidden w-full object-cover"
                autoPlay
                loop
                muted
                onCanPlayThrough={() => {
                  // Do stuff
                }}
              >
                <source src={bgvideo} type="video/webm" />
              </video>

              <video
                className="bg-height hidden md:block sm:block w-full object-cover"
                autoPlay
                loop
                muted
                onCanPlayThrough={() => {
                  // Do stuff
                }}
              >
                <source src={bgvideo} type="video/webm" />
              </video>
            </div>
          </SwiperSlide> */}
          <SwiperSlide className="">
            <div className="bg-img relative">
              <div className="relative">
                <img
                  src={desktopbg}
                  alt=""
                  className="md:hidden h-[220px] w-full"
                />
                <img
                  src={desktopbg}
                  alt=""
                  className="hidden md:block w-full bg-height"
                />
              </div>

              <div className="absolute inset-0 flex items-center justify-center flex-col ">
                <img src={desktoplogo} alt="" className="w-[50%] sm:w-[40%] h-[40%] md:w-[583px] md:h-[256px]" />
                <h1 className="text-[40px] md:text-[64px] font-[400] tracking-[7.04px] text-[#000] monarda pt-3 md:pt-5 text-center">
                  all things art
                </h1>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>

      {/* =====================    section-2       =============================== */}

      <div className="max-w-[1300px] mt-[1rem] mx-auto w-full px-5 md:px-10 mb-5">
        <div className="  bg-[#f6f6f6]">
          {/* <h2 className="text-left text-[32px] md:text-[80px] text-[#0074BE] font-medium monarda">
            Flo Design
          </h2> */}

          {/* <div className="flex items-center">
            <span className="border-[1px] border-[#000000] w-[34px]"></span>
            <p className="text-[16px] md:text-[32px] text-[#060606] font-medium p-4 monarda">
              all things art
            </p>
            <span className="border-[1px] border-[#000000] w-[32px]"></span>
          </div> */}

          <div className="main-about-content">
            <div className="flex items-center">
              <span className="border-[1px] border-[#000000] w-[34px]"></span>
              <p className="text-[16px] md:text-[30px] text-[#060606] font-medium p-4 monarda">
                Introduction
              </p>
              <span className="border-[1px] border-[#000000] w-[32px]"></span>
            </div>
            {/* small screens */}
            <p className="text-[#060606] text-[13px] font-raleway font-normal leading-7 md:hidden tracking-[1.1px]  md:tracking-[2.2px]">
              At flodesign, we believe that art is not just a form of
              expression, but a gateway to exploration, discovery, and endless
              creativity. As an innovative art workshop company, we are
              dedicated to providing enriching experiences that ignite
              inspiration and cultivate a lifelong love for artistic expression.
            </p>
            {/* <p className="text-[#060606] text-[12px] font-raleway font-medium leading-7 md:hidden tracking-[1.1px]  md:tracking-[2.2px]">
            Flodesign was founded in 2021 with a passion for bringing art into the lives of individuals of all ages and backgrounds. Whether you're a seasoned artist or just discovering your creative potential, our workshops offer a welcoming environment where you can explore various mediums, techniques, and styles under the guidance of skilled instructors.
            </p> */}

            {/* big screens */}
            <p className="text-[#060606] text-[16px] font-raleway  leading-7 font-medium hidden md:block tracking-[1.1px]  md:tracking-[2.2px]">
              {" "}
              At flodesign, we believe that art is not just a form of
              expression, but a gateway to exploration, discovery, and endless
              creativity. As an innovative art workshop company, we are
              dedicated to providing enriching experiences that ignite
              inspiration and cultivate a lifelong love for artistic expression.
            </p>

            <p className="text-[#060606] text-[16px] font-raleway leading-7 font-medium hidden md:block py-3 tracking-[1.1px]  md:tracking-[2.2px]">
              {" "}
              Flodesign was founded in 2021 with a passion for bringing art into
              the lives of individuals of all ages and backgrounds. Whether
              you're a seasoned artist or just discovering your creative
              potential, our workshops offer a welcoming environment where you
              can explore various mediums, techniques, and styles under the
              guidance of skilled instructors.
            </p>

            <div className="py-5">
              <button
                onClick={() => navigate("/aboutus")}
                className="w-full  tracking-[1.32px] bg-[#61D4FB] shadow-custom py-[10px] px-[12px] text-[#101010] font-bold text-[13px] font-raleway md:hidden hover:bg-[#0074BE] hover:text-[#fff] mb-4"
              >
                Learn More
              </button>

              <button
                onClick={() => navigate("/aboutus")}
                className="w-80 tracking-[2.64px] mx-auto shadow-custom bg-[#61D4FB] py-[12px] px-[64px] text-[#101010] font-bold text-[24px] font-raleway hidden md:block hover:bg-[#0074BE] hover:text-[#fff] mb-5"
              >
                Learn More
              </button>
            </div>
          </div>
        </div>

        {/* ======================   section-3   ========================================== */}

        <div className=" mb-2  bg-[#f6f6f6] ">
          <h2 className="text-left text-[32px] md:text-[70px] text-[#0074BE] font-medium monarda ">
            Manoghnya Pachipala
          </h2>

          {/* <h2 className="text-right w-[50%]"></h2> */}

          <div className="flex items-center justify-end w-full md:w-[80%] lg:w-[65%] xl:w-[55%]">
            <p className="text-[14px] md:text-[22px] text-[#060606] font-medium p-4 monarda">
              MSc Organisational and Social Psychology
            </p>
          </div>

          <div className="flex items-center">
            <span className="border-[1px] border-[#000000] w-[34px]"></span>
            <p className="text-[16px] md:text-[30px] text-[#060606] font-medium p-4 monarda">
              Founder
            </p>
            <span className="border-[1px] border-[#000000] w-[32px]"></span>
          </div>

          <div className="main-about-content md:flex md:flex-row-reverse md:justify-between xl:flex-row-reverse md:gap-10">
            <div className="about-img flex justify-center items-center py-4 md:hidden">
              <img
                src={profileImg}
                alt="about-img"
                className="w-auto h-[400px] md:w-[600px] object-center"
              />
            </div>

            <div className="about-img py-4 hidden md:block lg:mt-[-50px]">
              <img
                src={profileImg}
                alt="about-img"
                className="object-center h-[350px] lg:h-[450px]"
              />
            </div>

            <div className="about-content md:w-[60%] xl:w-[800px]">
              {/* small screen */}
              <p className=" text-[#060606] tracking-[1.1px] mb-5 mt-3 leading-7 md:text-[16px]  md:tracking-[2.2px] text-[13px] font-raleway font-[400] md:hidden">
                Art became an integral part of my life from a tender age, all
                thanks to my mother who is not only a professional artist but
                also my greatest inspiration. It was at the age of 5 when I
                experienced the exhilaration of winning my very first art
                competition, a moment that ignited a lifelong passion within me.
              </p>

              {/* big screens */}
              <p className="mb-5 text-[#060606] tracking-[1.1px]  md:tracking-[2.2px] text-[15px] md:text-[16px] leading-7 font-raleway font-[400] hidden md:block">
                Art became an integral part of my life from a tender age, all
                thanks to my mother who is not only a professional artist but
                also my greatest inspiration. It was at the age of 5 when I
                experienced the exhilaration of winning my very first art
                competition, a moment that ignited a lifelong passion within me.
                Since then, my artistic journey has been nothing short of
                extraordinary, as I enthusiastically engaged in nearly 150 art
                competitions across India and even ventured into the
                international arena, exploring diverse art forms, themes, and
                styles.
              </p>

              <p className="text-[#060606] tracking-[1.1px]  md:tracking-[2.2px] leading-7 text-[16px] font-raleway font-[200] hidden md:block ">
                {" "}
                As a young Psychologist and a certified art therapist, I
                understand the importance of emotions and expression and the
                wonders it can create when combined together. I have curated,
                customised and conducted more than 250 art workshops across
                hyderabad, creating and igniting a community of art lovers in
                the city.
              </p>
            </div>
          </div>
          <div className="mt-0 md:p-0 categort-award-section flex flex-col mx-1 md:mx-4 md:flex-row  md:justify-around xl:justify-between md:items-start md:gap-8 md:my-0 py-0 text-[#060606] md:mb-14">
            <div className="first-category xl:mt-5 ">
              <div className="mt-4 one  flex flex-row items-center gap-[1.5rem] md:gap-8 xl:w-auto  ">
                <img
                  src={trophy}
                  alt=""
                  className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
                />
                <div className="text md:mt-3">
                  <h2 className="text-[1rem]  xl:text-[20px] font-bold font-raleway tracking-wider">
                    The Balaratna Award in Painting
                  </h2>
                  <p className="text-[13px]  xl:text-[17px] font-[400] font-raleway tracking-wider">
                    - Recognized for Exceptional Talent
                  </p>
                </div>
              </div>

              <div className="mt-4 xl:mt-10 flex flex-row items-center gap-[1.5rem] md:gap-8 my-4 md:w-[300px] xl:w-auto  ">
                {/* <img
                src={paint}
                alt=''
                className='w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]'
              /> */}

                <div>
                  <img
                    alt=""
                    className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
                    src={horlicksWizard}
                  />
                </div>

                <div className="text md:mt-3">
                  <h2 className="text-[1rem]  xl:text-[20px] font-bold font-raleway ">
                    Horlicks WizKids Competition
                  </h2>
                  <p className="text-[13px]  xl:text-[17px] font-[400] font-raleway tracking-wider">
                    - Three-Time Consecutive Winner
                  </p>
                </div>
              </div>

              <div className="mt-4 xl:mt-10    flex flex-row items-center  gap-[1.5rem] md:gap-8 my-4   md:w-[300px] xl:w-auto  ">
                {/* <img
                src={book}
                alt=''
                className='w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]'
              /> */}

                <img
                  alt=""
                  className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
                  src={limcaBook}
                />
                <div className="text md:mt-3">
                  <h2 className="text-[1rem] xl:text-[20px] font-bold font-raleway tracking-wider">
                    Limca Book of Records
                  </h2>
                  <p className="text-[13px]  xl:text-[17px] font-[400] font-raleway tracking-wider">
                    - Contribution to the Longest Painted Flag
                  </p>
                </div>
              </div>
            </div>

            <div className="second-category md:mb-10">
              <div className="mt-1 xl:mt-10 flex flex-row items-center  gap-[1.5rem] md:gap-8 my-4   md:w-[300px] xl:w-auto  ">
                {/* <img
                src={prize}
                alt=''
                className='w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]'
              /> */}

                <img
                  alt=""
                  className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
                  src={medal}
                />
                <div className="text md:mt-3">
                  <h2 className="text-[1rem]  xl:text-[20px] font-bold font-raleway tracking-wider">
                    Multiple Art Competition Wins
                  </h2>
                  <p className="text-[13px] font-[400] xl:text-[17px]  font-raleway tracking-wider">
                    - Across India and Internationally
                  </p>
                </div>
              </div>

              <div className="mt-4 xl:mt-10 md:mb-10 one  flex flex-row items-center  gap-[1.5rem] md:gap-8 my-4   md:w-[300px] xl:w-auto  ">
                {/* <img
                src={color}
                alt=''
                className='w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]'
              /> */}

                <img
                  alt=""
                  className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
                  src={artExhibition}
                />
                <div className="text md:mt-3">
                  <h2 className="text-[1rem]  xl:text-[20px] font-bold font-raleway tracking-wider">
                    Solo Art Exhibition at Age 7
                  </h2>
                  <p className="text-[13px]  xl:text-[17px] font-[400] font-raleway tracking-wider">
                    - Showcasing 30 Captivating Paintings
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>

        {/* ======================   section-3     ========================================== */}

        <div className=" bg-[#f6f6f6] " id="upcomingEvent">
          <div className="flex justify-between items-center mt-[2.5rem] md:hidden">
            <h1 className="text-[18px] text-[#060606] font-bold font-raleway tracking-[1.98px]">
              Featured Events
            </h1>
            <p className="text-[#808080] border-b-[2px] font-bold border-b-[#808080]] cursor-pointer text-[13px] tracking-[1.32px]">
              View All
            </p>
          </div>

          <h2 className="hidden md:flex text-[40px] font-bold font-raleway text-[#060606] py-2">
            Upcoming Events
          </h2>

          <div className="card-section py-2">
            <div className="mt-2">
              <Swiper
                slidesPerView={"auto"} // Automatically adjust slides per view
                spaceBetween={10}
                navigation={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Navigation]}
                className="mySwiper"
                breakpoints={{
                  768: {
                    slidesPerView: 2, // Show two slides per view on screens >= 768px
                  },
                  1024: {
                    slidesPerView: 2, // Show three slides per view on screens >= 1024px
                  },
                }}
              >
                {/* {status === "error" ? (
            <div>
              <center>
                <h3>NO RECORDS FOUND</h3>
              </center>
            </div>
          ) : (
            <>
              {data
                ?.filter((datas) => datas?.status !== "COMPLETED") // Filter out events where finished is true
                .map((dats, index) => (
                  <EventCard
                    key={index} // Make sure to add a unique key for each item when mapping in React
                    index={index}
                    navigate={`/bookingpage/${dats?.slugid}`}
                    eventDate={moment(dats?.expiryDate).format("ddd, Do MMMM")}
                    shortDesc={dats?.description}
                    title={dats?.title}
                    price={dats?.price}
                    source={dats?.eventImage}
                  />
                ))}
            </>
          )}     */}

                {isLoading ? (
                  <div className="w-full flex justify-center items-center">
                    <ThreeDots
                      height="80"
                      width="130"
                      radius="9"
                      color="#61D4FB"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />{" "}
                  </div>
                ) : (
                  <>
                    {status === "error" ? (
                      <div>
                        <center>
                          <h3>NO RECORDS FOUND</h3>
                        </center>
                      </div>
                    ) : (
                      <>
                        {data
                          ?.slice(0, 6)
                          .reverse()
                          .filter((datas) => datas?.status !== "COMPLETED") // Filter out events where finished is true
                          .map((dats, index) => (
                            <SwiperSlide key={index} className="py-5 px-3">
                              <div className="flex justify-center p-2">
                                <UpcomingCard
                                  key={index} // Make sure to add a unique key for each item when mapping in React
                                  index={index}
                                  navigate={`/bookingpage/${dats?.slugid}`}
                                  eventDate={moment.utc(dats?.expiryDate).format(
                                    "ddd, Do MMMM - h:mm A"
                                  )}
                                  shortDesc={dats?.description}
                                  title={dats?.title}
                                  price={dats?.price}
                                  source={dats?.eventImage}
                                  links={EVENTS_LINKS[index]}
                                />
                              </div>
                            </SwiperSlide>
                          ))}{" "}
                      </>
                    )}
                  </>
                )}
              </Swiper>
            </div>
          </div>
        </div>

        {/* ======================== section-4 ================================================= */}

        <div
          id="contact"
          className="contact-section md:px-10 bg-[#f6f6f6] py-3 pb-20 
      md:flex md:justify-center   gap-5"
        >
          <div className="flex justify-left items-center gap-4 pb-2 md:hidden">
            <h2 className="text-[17.2px] text-[#060606] font-bold text-left tracking-[1.98px]">
              Contact Us
            </h2>

            <span className="border-[1px] border-[#808080] w-16 sm:w-56"></span>
          </div>

          <div className="barcode-side hidden md:block w-1/2">
            <h2 className="text-[40px] font-bold font-raleway text-[#060606] py-2 md:tracking-[4.4px]">
              Contact Us
            </h2>
            <p className="text-[#060606] text-[15px] font-medium font-raleway py-2 tracking-[2.2px]">
              We would love to hear from you! Contact us at Flo Design to
              discuss your art workshop needs, partnership opportunities, or any
              inquiries you may have. Let's collaborate and bring creative
              experiences to life. Get in touch with us today!
            </p>

            <img
              src={barcode}
              alt=""
              className="mx-auto w-[300px] h-[400px] py-4"
            />
          </div>

          <div className="contact-form mt-[16px] md:w-1/2 md:py-10 w-full">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-5 w-full">
                <div className="flex flex-row gap-4 w-full ">
                  <div className="flex flex-col gap-2 w-1/2">
                    <input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      style={{
                        border: "1px solid var(--Stroke, #000)",
                        padding: "10px ",
                      }}
                      type="text"
                      placeholder="Name"
                      className="text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full outline-none pl-[1rem] placeholder:font-raleway placeholder:text-[#000] placeholder:text-[13px] placeholder:font-[400] placeholder:leading-[99%]"
                    />
                    {errors.name && touched.name ? (
                      <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                        *{errors.name}
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-col gap-2 w-1/2">
                    <input
                      onChange={handleChange}
                      value={values.mobile}
                      name="mobile"
                      style={{
                        border: "1px solid var(--Stroke, #000)",
                        padding: "10px",
                      }}
                      type="text"
                      placeholder="Mobile Number"
                      className="text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full outline-none pl-[1rem] placeholder:font-raleway placeholder:text-[#000] placeholder:text-[13px] placeholder:font-[400] placeholder:leading-[99%]"
                    />

                    {errors.mobile && touched.mobile ? (
                      <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                        *{errors.mobile}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <input
                    name="email"
                    onChange={handleChange}
                    value={values.email}
                    style={{
                      border: "1px solid var(--Stroke, #000)",
                      padding: "10px",
                    }}
                    type="email"
                    placeholder="Email"
                    className="text-[14px] sm:text-[16px] h-[45px]  md:h-[50px] w-full outline-none pl-[1rem] placeholder:font-raleway placeholder:text-[#000] placeholder:text-[13px] placeholder:font-[400] placeholder:leading-[99%]"
                  />
                  {errors.email && touched.email ? (
                    <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                      *{errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="flex flex-col gap-2 w-full">
                  <div
                    style={{
                      border: "1px solid var(--Stroke, #000)",
                    }}
                    className="flex flex-col gap-2 w-full h-auto"
                  >
                    {/* <Select

                  
                    containerProps={{
                      className:
                        "h-[45px]  md:h-[50px] bg-[#fff] border border-solid border-[ #000] w-full outline-none rounded   placeholder:font-raleway placeholder:text-[#000] placeholder:text-[12px] placeholder:font-[400] placeholder:leading-[99%]",
                    }}
                    label='Type of Enquiry /Request'>
                    <Option value='Request'>Type of Enquiry / Request</Option>
                    <Option value='Complain'>Complain</Option>
                    <Option value='Request'>Request</Option>
                    <Option value='Order'>Order</Option>
                    <Option value='Events'>Events</Option>
                  </Select> */}

                    <select
                      name="request"
                      onChange={handleChange}
                      value={values.request}
                      style={{ borderRight: "20px solid transparent" }}
                      className="text-[14px] sm:text-[16px] h-[45px] md:h-[50px] pl-[1rem] outline-none rounded placeholder:font-raleway placeholder:text-[#000] placeholder:text-[12px] placeholder:font-[400] placeholder:leading-[99%]"
                    >
                      <option value="" disabled selected>
                        Type of Enquiry / Request
                      </option>
                      <option className="h-[20px]" value="Complain">
                        Complain
                      </option>
                      <option className="py-2" value="Request">
                        Request
                      </option>
                      <option className="py-2" value="Order">
                        Order
                      </option>
                      <option className="py-2" value="Events">
                        Events
                      </option>
                    </select>

                    {/* <Select
                  
                    className=' w-full '
                    options={options}
                    name='request'
                    onChange={handleChange}
                    value={values.request}
                    placeholder='Type of Enquiry / Request'
                    styles={{
                      control: (provided) => ({
                        display: "flex",

                        backgroundColor: "white",
                        border: "1px solid #000", // Adjust as needed
                        borderRadius: "4px", // Adjust as needed

                        fontFamily: "Raleway",
                        color: "#000",
                        fontSize: "12px",
                        fontWeight: "400",
                        lineHeight: "99%",

                        width: "100%",
                        height: "45px",

                        "@media (min-width: 768px)": {
                          height: "50px",
                          width: "100%",
                        },
                      }),

                      option: (provided) => ({
                        ...provided,
                        color: "#000",
                        backgroundColor: "transparent",
                      }),
                      placeholder: (styled) => ({
                        ...styled,
                        color: "#000",
                        fontFamily: "Raleway",
                        fontSize: "12px",
                        fontWeight: "400",
                      }),

                      indicatorSeparator: (provided) => ({
                        display: "none",
                      }),
                      dropdownIndicator: (provided) => ({
                        // Adjust as needed
                        color: "#666666",
                        padding: "16px",
                      }),
                    }}
                  />  */}
                  </div>

                  {errors.request && touched.request ? (
                    <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                      *{errors.request}
                    </div>
                  ) : null}
                </div>
                <div className="flex flex-col gap-2 w-full">
                  <textarea
                    name="inbox_content"
                    onChange={handleChange}
                    value={values.inbox_content}
                    style={{
                      border: "1px solid var(--Stroke, #000)",
                      padding: "10px 0px 10px 10px",
                    }}
                    placeholder="Message"
                    className="text-[14px] sm:text-[16px] h-[120px] md:h-[140px] w-full outline-none pl-[1rem]  placeholder:font-raleway placeholder:text-[#000] placeholder:text-[13px] placeholder:font-[400] placeholder:leading-[99%]"
                  />
                  {errors.inbox_content && touched.inbox_content ? (
                    <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                      *{errors.inbox_content}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* Google reCAPTCHA */}
              <div className="my-5">
                <ReCAPTCHA
                  sitekey="6Lfpgw4qAAAAAMWQyRyi7GF2pEP12EA53oY2eKpn"
                  // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                  //sitekey="6LdgkBIpAAAAAF_2L3_uT2LgNcckTZSW3vjvsavM"
                  onChange={(value) => {
                    console.log("reCAPTCHA value:", value);
                    setRecaptchaValue(value);
                    // You can use the reCAPTCHA value in your form submission logic
                  }}
                />
              </div>
              <div className="button flex mx-auto mt-[15px]">
                {loader ? (
                  <div className="w-full flex justify-center items-center">
                    <ThreeDots
                      height="80"
                      width="130"
                      radius="9"
                      color="#61D4FB"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />{" "}
                  </div>
                ) : (
                  <button
                    style={{
                      boxShadow: " 0px 10px 30px 0px rgba(13, 13, 13, 0.15)",
                    }}
                    className="mx-auto bg-[#61D4FB] hover:bg-[#0074BE] hover:text-[#fff]  text-center md:px-[59px] md:py-[15px] px-[59px] py-[15px] font-raleway text-[13px] md:text-[17px] font-bold text-[#101010] "
                    type="submit"
                  >
                    Submit
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>

        {/*  ================================= footer =============================================== */}
      </div>
    </div>
  );
};

export default Home;
