import React, { useEffect } from "react";
import footerbg from "../assets/footerbg.png";
import desktopprivacy from "../assets/desktoprivacy.png";

const Privacypolicy = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-full bg-[#F6F6F6] ">
      <div className="privacy-container pb-14">
        <img src={footerbg} alt="" className="w-full md:hidden" />
        <img src={desktopprivacy} alt="" className="w-full hidden md:block" />

        <div className="px-4 md:px-32 py-4 md:py-8 font-raleway">
          <div className="bg-gray-100">
            <p className="mb-6 font-bold text-[18px] md:text-[24px] tracking-wider">
              Effective Date: 14-08-2023
            </p>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              This Privacy Policy describes how Flo Design ("we," "our," or
              "us") collects, uses, and shares your personal information when
              you interact with our website ("Website") and participate in our
              online and offline events related to painting, including sip and
              paint sessions. By using our Website and participating in our
              events, you agree to the terms outlined in this Privacy Policy.
            </p>
            <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
              1. Information We Collect
            </h2>
            <p className="mb-3 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              We collect certain personal and banking information from you when
              you book tickets to our events via flodesign.in. The information
              we collect may include:
            </p>
            <ul className="list-disc ml-6 mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              <li>Name</li>
              <li>Contact Information (email address, phone number)</li>
              <li>
                Payment Information (credit card details, billing address)
              </li>
              <li>Other relevant information you provide to us</li>
            </ul>
            <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
              2. How We Use Your Information
            </h2>
            <p className="mb-3 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              We use the information collected to:
            </p>
            <ul className="list-disc ml-6 mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              <li>Process your ticket bookings and payments</li>
              <li>Send you event confirmations and updates</li>
              <li>Share newsletters and information about upcoming events</li>
              <li>Improve our services and personalize your experience</li>
              <li>Comply with legal and regulatory requirements</li>
            </ul>
            <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
              3. Cookies and Tracking Technologies
            </h2>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              We use cookies and similar tracking technologies to enhance your
              browsing experience on our Website. These technologies collect
              information about your interactions with the Website, such as
              pages visited, links clicked, and other browsing behaviors. You
              can manage your cookie preferences through your browser settings.
            </p>
            <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
              4. Data Sharing
            </h2>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              We do not sell, rent, or share your personal information with
              third parties for their marketing purposes. However, we may share
              your information with trusted service providers who assist us in
              conducting our business operations, such as payment processors and
              email service providers. These third parties are required to
              maintain the confidentiality and security of your information.
            </p>

            <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
              5. Data Security
            </h2>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              We implement appropriate security measures to protect your
              personal information from unauthorized access, disclosure,
              alteration, or destruction. However, no data transmission over the
              internet or electronic storage is entirely secure, and we cannot
              guarantee absolute security.
            </p>

            <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
              6. Your Choices
            </h2>
            <p className="mb-3 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              You have the right to:
            </p>
            <ul className="list-disc ml-6 mb-3 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              <li>Access, correct, or update your personal information</li>
              <li>Withdraw consent for certain processing activities</li>
              <li>Opt out of receiving marketing communications</li>
              <li>Delete your account and personal data</li>
            </ul>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              To exercise these rights, please contact us at the contact
              information provided below.
            </p>

            <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
              7. Updates to this Privacy Policy
            </h2>
            <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or for legal reasons. We will notify you
              of any material changes by posting the updated Privacy Policy on
              our Website.
            </p>

            <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
              8. Contact Us
            </h2>
            <p className="mb-4 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              If you have any questions, concerns, or requests related to this
              Privacy Policy, please contact us at:
            </p>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              FloDesign
            </p>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              Address: FLO DESIGN,8-2-465, FLAT NO.101, STUDIO SYCAMORE, ROAD
              NO.4, OPP UNION BANK OF INDIA, BANJARA HILLS, HYDERABAD 500034
            </p>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              Email: info@flodesign.in
            </p>
            <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              Phone: +91 81060 70259
            </p>

            <p className="text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
              By using our Website and participating in our events, you
              acknowledge that you have read and understood this Privacy Policy
              and agree to the collection, use, and sharing of your information
              as described herein.
            </p>

            <p className="text-[16px] md:text-[22px] text-gray-500 mt-4 tracking-wider">
              Last Updated: 15-08-2023
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
