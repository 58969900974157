import React, { useEffect, useState } from "react";

import Footer from "./Footer";
import cardimg from "../assets/card-1.png";
import { useNavigate } from "react-router-dom";
import { Button, CardActionArea, CardActions } from "@mui/material";
import EventCard from "./cards/EventCard";
import { useQuery } from "react-query";
import { APIUrls } from "../api/APIclient";
import axios from "axios";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import { useAuth } from "../Context/AuthContext";

import { userActions } from "../Context/Store";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

const itemsPerPage = 5;

const EVENTS_LINKS = [
  "https://pages.razorpay.com/pl_PWd7roF9ua6d18/view",
  "https://pages.razorpay.com/pl_PV5pCByRC9bEX7/view",
  "https://pages.razorpay.com/pl_PV5zX0MkM4Rmmj/view"
];

const Event = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [currentPage, setCurrentPage] = useState(0);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isError, refetch, data, error, status } = useQuery({
    queryKey: ["benificers"],
    queryFn: async () => {
      try {
        dispatch(userActions.startLoadings());
        const response = await fetch(APIUrls.fetchEvents, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const responseJson = await response.json();

        console.log(responseJson);
        if (_.isEmpty(responseJson)) {
          dispatch(userActions.stopLoading());
          return;
        }

        if (responseJson?.code === 200) {
          dispatch(userActions.stopLoading());
          if (responseJson.response.eventCount !== 0) {
            const benarray = responseJson.response.eventss;

            return benarray;
          }

          const message = responseJson.response.message;
        }

        // if (responseJson.response.beneficer_count == 0) {
        //   throw new Error("There are no benificers available");
        // }
        // if (responseJson?.code === 492) {
        //   const message = responseJson.response.message;
        //   throw new Error("There is no benificary");
        // }

        if (responseJson?.code === 400) {
          dispatch(userActions.stopLoading());
          const message = responseJson.response.message;
          throw new Error("Something went Wrong ,Try again later !");
        }

        console.log(responseJson);
      } catch (error) {
        dispatch(userActions.stopLoading());
        console.log("error", error);
      }
    },
  });

  // const handlePageChange = ({ selected }) => {
  //   setCurrentPage(selected);
  // };

  // const offset = currentPage * itemsPerPage;
  // const paginatedProducts = data.slice(offset, offset + itemsPerPage);

  return (
    <div className='container mx-auto px-4 md:px-12 '>
      {console.log("DATA:", data)}
      <h2 className='text-center text-[32px] md:text-[80px] font-raleway py-7 text-[#060606] tracking-wider italic font-extrabold bg-gradient-to-r from-blue-900 to-blue-300 text-transparent bg-clip-text'>
        “Events”
      </h2>

      <div className=''>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-[1.5rem] md:gap-[2rem] lg:gap-[2.5rem] pb-10 lg:pb-20'>
          {console.log(
            "DATA:",
            data?.filter((datas) => datas?.finished === false)
          )}

          {/* Article */}
          {status === "error" ? (
            <div>
              <center>
                <h3>NO RECORDS FOUND</h3>
              </center>
            </div>
          ) : (
            <>
              {data
                ?.filter((datas) => datas?.status !== "COMPLETED").reverse() // Filter out events where finished is true
                .map((dats, index) => (
                  <EventCard
                    key={index} // Make sure to add a unique key for each item when mapping in React
                    index={index}
                    navigate={`/bookingpage/${dats?.slugid}`}
                    eventDate={moment.utc(dats?.expiryDate).format("ddd, Do MMMM - h:mm A")}
                    shortDesc={dats?.description}
                    title={dats?.title}
                    price={dats?.price}
                    source={dats?.eventImage}
                    links={EVENTS_LINKS[index]}
                  />
                ))}
            </>
          )}

          {/* {data?.map((postings, index) => (
            <EventCard
              index={index}
              navigate={`/bookingpage/${postings?.slugid}`}
              eventDate={moment(postings?.expiryDate).format("Do MMMM YYYY")}
              shortDesc={postings?.description}
              title={postings?.title}
              price={postings?.price}
              source={postings?.eventImage}
            />
          ))} */}

          {/* <button
        className='border-[1px] border-[#808080] mx-auto mt-[2rem] p-2 w-[171px] h-[56px] text-center bg-[
         #F6F6F6] text-[24px] font-bold font-raleway hidden md:block mb-14'>
        Load More
      </button> */}

          {/* <ReactPaginate
        previousLabel={'← Previous'}
        nextLabel={'Next →'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(data.length / itemsPerPage)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
      /> */}
        </div>
      </div>
    </div>
  );
};

export default Event;
