import { object, string, number, date, InferType } from "yup";

const bookingSchema = object({
  FirstName: string().required("First Name field is required !"),
  LastName: string().required("Last Name is required !"),
  Mobile: string().required("Phone number field is required !"),
  Email: string().email().required("Email field is required !"),
  Age: string().required("Age  field is required !"),
  address: string().required("Address field is required !"),
  state: string().required("State field is required !"),
  pincode: string().required("Pincode field is required !"),
  Quantity: string().required("Quantity field is required !"),
});

export default bookingSchema;
