import { createSlice } from "@reduxjs/toolkit";
// import moment from "moment";


const initialState = {
  loading: false,
};

export const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    startLoadings(state, action) {
      state.loading = true
    
    },

    stopLoading(state, action) {
        state.loading = false
      
      }
  
     
  },
});
