import React, { createContext, useContext, useState, useEffect } from 'react';
import { ClipLoader } from 'react-spinners';
import { css } from '@emotion/react';
import { useSelector, useDispatch } from "react-redux";

import Loader from "../components/Loader";


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false); 
    const isLoadings = useSelector((state) => state.indexReducer.loading);
    const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

    function hellow (){ 

        return console.log("hellow")
    }


    function startLoading() { 

        if(!isLoading) {

            setIsLoading(true)
        }
    }

    function stopLoading() { 

        if(isLoading) {

            setIsLoading(false)
        }
    }

  return (
    <AuthContext.Provider value={{hellow ,startLoading , stopLoading}}>
        {isLoadings ? <Loader /> : children}
         {/* {children} */}
     
    </AuthContext.Provider>
  );
};


export const useAuth = () => {
    return useContext(AuthContext);
  };