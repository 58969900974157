import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'
import instruction1 from "../assets/pbn_instruction1.png"
import instruction2 from "../assets/pbn_instruction2.png"
import instruction3 from "../assets/pbn_instruction3.png"
import instruction4 from "../assets/pbn_instruction4.png"
import instruction5 from "../assets/pbn_instruction5.png"

export default function ShopDetailsModal({ setShowModal, data }) {
  return (
    <div className="flex items-center justify-center absolute h-full w-full inset-0 z-50">
      <div className='fixed bg-[#000000E6] w-full h-full flex items-center justify-center'>
        <div className="flex flex-col gap-4 bg-white w-[95%] md:w-[90%] h-[90%] sm:h-[95%] px-4 md:px-10 py-4 overflow-auto">
          <div className='flex items-center justify-end'>
            <button
              onClick={() => setShowModal(false)}
              className="text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] hover:bg-[#FF0023] hover:text-white"
              size="sm"
            >
              <Icon icon="material-symbols:close" className="text-[1.5rem]" />
            </button>
          </div>
          {/* <div className='grid place-items-center grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6'>
          </div> */}
          {/* Container */}
          <div className='flex flex-col md:flex-row md:items-start gap-8'>
            <div className='flex-1 flex flex-col gap-4'>
              <img src={data.source} alt="img" className='w-full' />
              {/* <h2 className='text-[18px] text-[#060606] md:text-[28px] md:font-[700] font-raleway font-[700] py-3 tracking-[1.98px] md:tracking-[4px] text-left'>
                Instructions
              </h2> */}
              <div className='hidden md:grid grid-cols-2 place-items-center gap-2'>
                <img src={instruction1} alt="instruction_image" className='' />
                <img src={instruction2} alt="instruction_image" className='' />
                <img src={instruction3} alt="instruction_image" className='' />
                <img src={instruction5} alt="instruction_image" className='' />
              </div>
            </div>
            <div className='flex-1'>
              <div className='flex justify-between items-center gap-2 flex-wrap'>
                <h1 className='text-[18px] text-[#060606] md:text-[38px] md:font-[700] font-raleway font-[700] py-3 tracking-[1.98px] md:tracking-[7.04px] text-left'>
                  {data.title}
                </h1>
                <h1>
                  <h1 className='text-[18px] text-[#060606] md:text-[38px] md:font-[700] font-raleway font-[700] py-3 tracking-[1.98px] md:tracking-[4px] text-left'>
                    ₹ {data.price}
                  </h1>
                </h1>
              </div>
              <p className="text-[12px] text-[#060606] font-normal  md:hidden font-raleway leading-[148%] tracking-[1.32px] ">
                {data?.description || "NO DESCRIPTION"}
              </p>

              <p className="text-[17px] hidden md:block font-raleway font-[400] tracking-[2.22px]">
                {data?.description || "NO DESCRIPTION"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
