import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ShopDetailsModal from '../ShopDetailsModal';

export default function ShopPaintingCard(props) {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const showModalHandler = (source, title, price, shortDesc) => {
    setShowModal(true);
    props.setStopScroll(true);
    setData((prevState) => {
      return {
        ...prevState,
        source: source,
        title: title,
        price: price,
        description: shortDesc,
      }
    })
  }

  return (
    <div
      key={props.index}
      className='w-full flex flex-col gap-[1.5rem] h-[525px] overflow-auto'
      style={{
        boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)",
        background: "#fff",
      }}>
      <div className='flex flex-col justify-between gap-[1rem] h-full m-4'>
        <div className='flex flex-col gap-[1rem] '>
          <div className='w-full flex flex-col gap-2 items-center justify-center'>
            <img
              alt='Placeholder'
              className='block h-[150px] lg:h-[170px]'
              src={props.source}
            />
            <div className='box bg-blue-gray-400 hover:bg-blue-gray-600 text-center w-full'>
              <button type='button' onClick={() => showModalHandler(props.source, props.title, props.price, props.shortDesc)}
                className='text-black hover:text-white text-lg font-bold w-full p-2'>
                View Details
              </button>
            </div>
          </div>

          <div className='flex flex-col gap-[0.5rem]'>
            <div className='flex flex-row justify-between '>
              <h1 className='font-raleway text-[1rem] xl:text-[1.2rem] 2xl:text-[1.5rem] font-bold lg:font-[800] text-[#060606] leading-[99%] '>
                {props.title}
              </h1>

              <p className='font-raleway text-[1rem] xl:text-[1.2rem] 2xl:text-[1.5rem] font-bold lg:font-[800] text-[#060606] leading-[99%] '>
                ₹{props.price}
              </p>
            </div>

            <p className='font-raleway text-[0.8rem] lg:text-[1rem] font-normal text-[#060606] 
          line-clamp-5 leading-[1.7rem] '>
              {props.shortDesc}
            </p>
          </div>
        </div>
        <a href={props.paymentLink} target='_blank' rel='noreferrer'
          className='bg-[#61D4FB] hover:bg-[#0074BE] hover:text-[#fff] py-[0.9rem] font-raleway text-[1rem] font-[700] leading-[99%] text-[#101010] text-center'
          style={{ boxShadow: " 0px 10px 30px 0px rgba(13, 13, 13, 0.15)" }}>
          Buy it Now
        </a>
      </div>
      {
        showModal && <ShopDetailsModal setShowModal={setShowModal} data={data} />
      }
    </div>
  )
}
