import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'
import neon1 from "../assets/gallery/neon1.jpg";
import neon2 from "../assets/gallery/neon2.jpg";
import neon3 from "../assets/gallery/neon3.jpg";
import neon4 from "../assets/gallery/neon4.jpg";
import neon5 from "../assets/gallery/neon5.jpg";
import neon6 from "../assets/gallery/neon6.jpg";
import neon7 from "../assets/gallery/neon7.jpg";
import neon8 from "../assets/gallery/neon8.jpg";
import neon9 from "../assets/gallery/neon9.jpg";
import neon10 from "../assets/gallery/neon10.jpg";

const IMAGES_ARRAY = [neon1, neon1, neon2, neon3, neon4, neon5, neon6, neon7, neon8, neon9, neon10];

export default function WorkshopModal({ setShowModal, headerName, imgArr }) {
  let arrayData = imgArr ? imgArr : IMAGES_ARRAY;
  return (
    <div className="flex items-center justify-center absolute h-full w-full inset-0 z-50">
      <div className='fixed bg-[#000000E6] w-full h-full flex items-center justify-center'>
        <div className="flex flex-col gap-4 bg-white w-[95%] md:w-[90%] h-[90%] sm:h-[95%] px-4 md:px-10 py-4 overflow-auto">
          <div className='flex items-center justify-between'>
            <h1 className='text-[22px] md:text-[24px] lg:text-[26px] font-raleway font-bold'>
              {headerName}
            </h1>
            <button
              onClick={() => setShowModal(false)}
              className="text-[#FF0023] bg-[#FDE4E8] border border-[#FF0023] hover:bg-[#FF0023] hover:text-white"
              size="sm"
            >
              <Icon icon="material-symbols:close" className="text-[1.5rem]" />
            </button>
          </div>
          <div className='grid place-items-center grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6'>
            {
              arrayData?.map((img, i) => {
                return (
                  <div key={i}>
                    <img src={img} alt={`img-${i}`} className='h-[160px] w-[160px] md:h-[200px] md:w-[200px] lg:h-[230px] lg:w-[230px] xl:h-[260px] xl:w-[260px]' />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}
