import React, { useState } from 'react'
import ShopPaintingCard from './cards/ShopPaintingCard'
import shop1 from "../assets/shop1.png";
import shop2 from "../assets/shop2.png";
import shop3 from "../assets/shop3.png";
import shop4 from "../assets/shop4.png";
import shop5 from "../assets/shop5.png";
import shop6 from "../assets/shop6.png";
import { Option, Select } from '@material-tailwind/react';

const SHOP_DATA = [
  {
    source: shop2,
    title: "Title 1",
    description: "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes, or avoids pleasure itself, because it is pleasure",
    price: 500,
    paymentLink: "https://rzp.io/l/48OraTcncU",
  },
  {
    source: shop3,
    title: "Title 2",
    description: "BUT I MUST EXPLAIN TO YOU HOW ALL THIS MISTAKEN IDEA OF DENOUNCING PLEASURE AND PRAISING PAIN WAS BORN AND I WILL GIVE YOU A COMPLETE ACCOUNT OF THE SYSTEM, AND EXPOUND THE ACTUAL TEACHINGS OF THE GREAT EXPLORER OF THE TRUTH, THE MASTER-BUILDER OF HUMAN HAPPINESS. NO ONE REJECTS, DISLIKES, OR AVOIDS PLEASURE ITSELF, BECAUSE IT IS PLEASURE, BUT BECAUSE THOSE WHO DO NOT KNOW HOW TO PURSUE PLEASURE RATIONALLY ENCOUNTER CONSEQUENCES THAT ARE EXTREMELY PAINFUL. NOR AGAIN IS THERE ANYONE WHO LOVES OR PURSUES OR DESIRES TO OBTAIN PAIN OF ITSELF,",
    price: 199,
    paymentLink: "https://rzp.io/l/qCg2iOEXiT",
  },
  {
    source: shop1,
    title: "Title 3",
    description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
    price: 750,
  },
  {
    source: shop1,
    title: "Title 4",
    description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
    price: 199,
  },
  {
    source: shop1,
    title: "Title 1",
    description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.",
    price: 499,
  },
]

export default function NewShop() {
  const [stopScroll, setStopScroll] = useState(false);
  const [filteredData, setFilteredData] = useState(SHOP_DATA);
  const [category, setCategory] = useState("");

  const categoryChangeHandler = (value) => {
    setCategory(value);
    if (value === "All") {
      setFilteredData(SHOP_DATA);
    } else {
      setFilteredData(SHOP_DATA.filter((data) => data.title === value));
    }
  }

  return (
    <div className={`w-full bg-[#f6f6f6] container mx-auto px-4 md:px-12`}>
      <h2 className="text-center text-[26px] md:text-[70px]  font-raleway py-7 tracking-wider italic font-extrabold bg-gradient-to-r from-blue-900 to-blue-300 text-transparent bg-clip-text">
        “Paint by Numbers Kit”
      </h2>
      {/* <h3 className='font-raleway text-[1rem] xl:text-[1.2rem] 2xl:text-[1.5rem] font-bold text-[#060606] leading-[99%] mb-4'>
          Categories
        </h3> */}
      <div className="mb-4 w-[65%] sm:w-[50%] lg:w-[30%]">
        <Select label="Filter By Category"
          className="text-[0.9rem] sm:text-[1rem] bg-white text-black font-[600] placeholder:text-black"
          value={category}
          onChange={(value) => categoryChangeHandler(value)}
        >
          <Option value="Title 1">Title 1</Option>
          <Option value="Title 2">Title 2</Option>
          <Option value="Title 3">Title 3</Option>
          <Option value="Title 4">Title 4</Option>
          <Option value="All">All</Option>
        </Select>
        {/* <RxCross2 /> */}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-[1.5rem] md:gap-[2rem] lg:gap-[2.5rem] lg:grid-cols-3 pb-10 lg:pb-20">
        {
          filteredData.map((data, index) =>
            <ShopPaintingCard
              key={index}
              index={index}
              // navigate={`/bookingpage/${dats?.slugid}`}
              // eventDate={moment(dats?.expiryDate).format("ddd, Do MMMM")}
              source={data?.source}
              title={data?.title}
              shortDesc={data?.description}
              price={data?.price}
              paymentLink={data?.paymentLink}
              setStopScroll={setStopScroll}
            />)
        }
      </div>
    </div>
  )
}
