import { Icon } from "@iconify/react";
import React, { useEffect, useState, useRef } from "react";
import paymentSuccess from "../assets/paymentSuccess.svg";
import paymenterror from "../assets/paymenterror.svg";

import orderItem from "../assets/orderItem.svg";


import _ from "lodash";

import { userActions } from "../Context/Store";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import { APIUrls } from "../api/APIclient";
import { useQuery } from "react-query";
import { BeatLoader } from "react-spinners";


const PaymentFailureCard = () => {


  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  const { orderid } = useParams();
  console.log(APIUrls.paymentStatus + "/" + orderid)

  console.log(orderid)
  const fetchData = async () => {
    // dispatch(userActions.startLoadings());

    console.log(APIUrls.paymentStatus + "/" + orderid)
    const response = await fetch(APIUrls.paymentStatus + "/" + orderid);
    const data = await response.json();
    
    //console.log(data)
    if (data.code === 200) {
      // dispatch(userActions.stopLoading());
      return data.response;
    } else {
      // dispatch(userActions.stopLoading());
      throw new Error("Error fetching data");
    }
  };

  const { data, isLoading, isError } = useQuery(
    ["singleEvent", orderid],
    () => fetchData(orderid),
    {
      enabled: !_.isEmpty(orderid), // Only enable the query if slugids is not empty
      onError: () => {
        // dispatch(userActions.stopLoading());
        // Handle error, for example, redirect to another page
        navigate("/");
      },
    }
  );

console.log(data?.UserDefinedData?.UserDefined1?.eventImage)


  return (
    <>
    {
     isLoading ? (
       <div className="bg-[#F6F6F6] py-[2.5rem] px-[2rem] flex justify-center align-middle items-center">
          <BeatLoader color="#36D7B7" loading={true} size={50} />
       </div>
    
     ) : (
    <div className='bg-[#F6F6F6] py-[2.5rem] px-[2rem] flex justify-center'>
      <div
        style={{
          border: "1px solid #B3B3B3",
          boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)",
        }}
        className='bg-[#F6F6F6] p-[1.3rem] lg:p-[2.5rem] md:w-[400px] lg:w-[700px] xl:w-[800px] flex flex-col justify-center items-center gap-[1.5rem] lg:gap-[2rem]'>
        <div className='flex flex-col items-center'>
          <img
            src={paymenterror}
            className='h-[3rem] w-[3rem] lg:h-[6.2rem] lg:w-[6.2rem]  mb-[1rem] lg:mb-[2rem]'
          />

          <h1 className='font-raleway text-[#060606] text-[1rem] lg:text-[2rem]  font-bold leading-[148%] tracking-[1px] lg:tracking-[3.2px]'>
            Could not process your order!
          </h1>

          <p className='font-raleway text-[0.8rem] lg:text-[1.2rem] text-[#060606] font-[400] leading-[148%] lg:tracking-[2.2px] text-center mt-1  '>
            Please retry after a while or Check your payment details before
            proceeding
          </p>
        </div>

        <div className='w-full flex flex-col '>
          <div
            style={{ borderBottom: "1px solid  #808080" }}
            className='w-full py-[1rem] flex flex-col gap-[1rem]'>
            <h1 className='font-raleway text-[0.8rem] lg:text-[1.5rem] text-[#060606] font-[700] leading-[99%] tracking-[1.2px] '>
              Transaction Id
            </h1>

            <div className='flex flex-row gap-[1rem]'>
              <p className='text-[#060606] font-raleway text-[1rem] lg:text-[1.3rem] font-[400] leading-[148%] tracking-[1px] lg:tracking-[2.2px] '>
              {data?.OrderKeyId}
              </p>

              <button>
                <Icon
                  icon='ph:copy-duotone'
                  className='text-[1rem] text-[#060606]'
                />
              </button>
            </div>
          </div>

          <div
            style={{ borderBottom: "1px solid  #808080" }}
            className='w-full py-[1rem] flex flex-col gap-[1rem]'>
            <h1 className='font-raleway text-[0.8rem] lg:text-[1.5rem] text-[#060606] font-[700] leading-[99%] tracking-[1.2px] '>
              Transaction Date
            </h1>

            <div className='flex flex-row gap-[1rem]'>
              <p className='text-[#060606] font-raleway text-[1rem] lg:text-[1.3rem] font-[400] leading-[148%] tracking-[1px] lg:tracking-[2.2px] '>
              {moment(data?.UserDefinedData?.UserDefined1?.created_At).format('YYYY-MM-DD HH:mm:ss')}
              </p>

              <button>
                <Icon
                  icon='ph:copy-duotone'
                  className='text-[1rem] text-[#060606]'
                />
              </button>
            </div>
          </div>
          {/* <div
            style={{ borderBottom: "1px solid  #808080" }}
            className='w-full py-[1rem] flex flex-col gap-[1rem]'>
            <h1 className='font-raleway text-[0.8rem] lg:text-[1.5rem] text-[#060606] font-[700] leading-[99%] tracking-[1.2px] '>
              Payment Method
            </h1>

            <div className='flex flex-row gap-[1rem]'>
              <p className='text-[#060606] font-raleway text-[1rem] lg:text-[1.3rem] font-[400] leading-[148%] tracking-[1px] lg:tracking-[2.2px] '>
                Mastercard ending with 2345
              </p>

              <button>
                <Icon
                  icon='ph:copy-duotone'
                  className='text-[1rem] text-[#060606]'
                />
              </button>
            </div>
          </div> */}
        </div>

        <div className='self-start flex flex-col gap-[1rem] w-full'>
          <h1 className='font-raleway text-[0.8rem] lg:text-[1.5rem] text-[#060606] font-[700] leading-[99%] tracking-[1.2px] '>
            Your Order
          </h1>

          <div className='flex flex-col md:flex-row md:justify-between w-full'>
            <div className='flex flex-col gap-[1rem] md:flex-row  '>
              <div className='flex flex-col gap-[1rem] md:flex-row'>
                <img src={data?.UserDefinedData?.UserDefined1?.event_id?.eventImage} className="w-[8rem] h-[8rem]" />

                <div className='flex flex-col gap-[0.5rem] lg:gap-[1rem] '>
                  <h1 className='font-raleway text-[1rem] lg:text-[1.3rem] text-[#060606] font-[700] leading-[99%] tracking-[1px]'>
                  { data?.UserDefinedData?.UserDefined1?.event_id?.title}
                  </h1>

                  <p className='font-raleway text-[1rem] lg:text-[1.3rem] text-[#060606] font-[400] leading-[148%] tracking-[1px]'>
                  { moment(data?.UserDefinedData?.UserDefined1?.event_id?.expiryDate).format('Do, MMMM YYYY')}
                  </p>
                  <p className="font-raleway text-[1rem] lg:text-[1.3rem] text-[#060606] font-[400] leading-[148%] tracking-[1px]">
                    Quantity: {data?.UserDefinedData?.UserDefined1?.Quantity}
                    </p>
                </div>
              </div>
            </div>
            <p className='font-raleway text-[1rem] lg:text-[1.3rem] text-[#060606] font-[700] leading-[99%] tracking-[1px] self-end md:self-start'>
              &#8377; { data?.UserDefinedData?.UserDefined1?.event_id?.price}
            </p>
          </div>
        </div>

        <div className='flex flex-row justify-between w-full'>
          <h1 className='font-raleway text-[0.8rem] lg:text-[1.5rem] text-[#060606] font-[700] leading-[99%] tracking-[1.2px] '>
            Grand Total
          </h1>

          <p className='font-raleway text-[1rem] lg:text-[1.3rem] text-[#060606] font-[700] leading-[99%] tracking-[1px] '>
            &#8377;  { data?.UserDefinedData?.UserDefined1?.amount}
          </p>
        </div>

        <button

         onClick={()=>navigate('/')}
          style={{ boxShadow: "0px 10px 30px 0px rgba(13, 13, 13, 0.15)" }}
          className='bg-[#61D4FB] w-full p-[1rem] text-[1rem] font-raleway text-[#101010] font-bold leading-[99%] tracking-[1px]'>
          Go Home
        </button>
      </div>
    </div>  )
     }
    </>
  );
};

export default PaymentFailureCard;
