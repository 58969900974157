import React, { useEffect } from "react";
import footerbg from "../assets/t&c.png";
import Footer from "./Footer";
import desktopprivacy from "../assets/desktoptt&c.png";

const Termandcondition = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="w-full bg-[#F6F6F6] ">
      <div className="privacy-container pb-14">
        <img src={footerbg} alt="" className="w-full md:hidden" />
        <img src={desktopprivacy} alt="" className="w-full hidden md:block" />

        <div className="px-4 md:px-32 py-4 md:py-8 font-raleway">
          <p className="mb-6 font-bold text-[18px] md:text-[24px] tracking-wider">
            Effective Date: 15-08-2023
          </p>
          <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            Please read these Terms and Conditions ("Terms") carefully before
            using our website ("Website") and booking tickets for events
            organized by Flo Design ("we," "our," or "us"). By using our Website
            and booking tickets for our events, you agree to be bound by these
            Terms. If you do not agree to these Terms, please do not use our
            Website or book tickets for our events.
          </p>

          <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
            1. Booking and Tickets
          </h2>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            a. Once you have booked a ticket for an event, the booking is
            considered final and cannot be canceled or refunded under any
            circumstances.
          </p>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            b. Ticket bookings are subject to availability, and we do not
            guarantee the availability of tickets for any particular event.
          </p>
          <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            c. All ticket prices are as stated on our Website and are subject to
            change without notice.
          </p>

          <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
            2. Rescheduling and Changes
          </h2>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            a. Once a ticket is booked, rescheduling to a different event date
            or time is not allowed.
          </p>
          <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            b. We reserve the right to make changes to event details, including
            dates, times, locations, and featured activities. Such changes will
            be communicated to you through the contact information provided
            during booking.
          </p>

          <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
            3. Event Participation
          </h2>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            a. Participants must adhere to the event guidelines and instructions
            provided by our staff or event facilitators.
          </p>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            b. Participants are responsible for their behavior during events and
            must treat fellow participants, staff, and facilitators with respect
            and courtesy.
          </p>
          <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            c. We reserve the right to remove any participant from an event if
            their behavior is deemed disruptive, inappropriate, or violates our
            policies.
          </p>

          <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
            4. Intellectual Property
          </h2>
          <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            a. All event materials, content, and intellectual property provided
            by us during events are protected by copyright and other
            intellectual property laws. Participants may not reproduce,
            distribute, or use such materials for commercial purposes without
            our explicit consent.
          </p>

          <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
            5. Liability and Waiver
          </h2>
          <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            a. Participation in our events involves physical activity and
            creativity. Participants acknowledge and assume the risks associated
            with such activities and waive any claims against Flo Design, its
            staff, and facilitators for any injuries or damages arising from
            participation.
          </p>

          <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
            6. Data Collection and Privacy
          </h2>
          <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            a. We collect and use personal information in accordance with our
            Privacy Policy, which can be found on our Website.
          </p>

          <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
            7. Modification and Termination
          </h2>
          <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            a. We reserve the right to modify or terminate our events, Website,
            or services at any time without notice.
          </p>

          <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
            8. Governing Law
          </h2>
          <p className="mb-6 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            a. These Terms shall be governed by and construed in accordance with
            the laws of Hyderabad, India, without regard to its conflict of law
            principles.
          </p>

          <h2 className="font-bold text-[18px] md:text-[24px] tracking-wider mb-2">
            9. Contact Us
          </h2>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            If you have any questions, concerns, or inquiries regarding these
            Terms, please contact us at
          </p>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            Flo Design
          </p>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            Address: 8-2-465, Flat No.101, Studio Sycamore, Road No.4, Opp Union
            Bank of India, Banjara Hills, Hyderabad 500034
          </p>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            Email: info@flodesign.in
          </p>
          <p className="mb-2 text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            Phone: +91 81060 70259
          </p>

          <p className="text-[13px] md:text-[16px] font-normal leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
            By using our Website and booking tickets for our events, you
            acknowledge that you have read, understood, and agree to abide by
            these Terms and Conditions.
          </p>

          <p className="text-[16px] md:text-[22px] text-gray-500 mt-4 tracking-wider">Last Updated: 15-08-2023</p>
        </div>
      </div>
    </div>
  );
};

export default Termandcondition;
