import React, { useEffect, useState, useRef } from "react";
import Footer from "./Footer";
import cardimg from "../assets/bookingcard.png";

import { userActions } from "../Context/Store";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { useFormik } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

import _ from "lodash";
import { APIUrls } from "../api/APIclient";
import { useQuery } from "react-query";
import bookingSchema from "../schema/bookingsmea";
import { BeatLoader } from "react-spinners";

import { useCallback } from "react";
import useRazorpay from "react-razorpay";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import "react-phone-input-2/lib/style.css";
const Booking = () => {
  const [Razorpay] = useRazorpay();
  const dispatch = useDispatch();
  const formPay = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
  }, []);

  const { slugids } = useParams();

  const fetchData = async () => {
    dispatch(userActions.startLoadings());
    const response = await fetch(APIUrls.fetchSingle + `/${slugids}`);
    const data = await response.json();
    if (data.code === 200) {
      dispatch(userActions.stopLoading());
      return data.response;
    } else {
      dispatch(userActions.stopLoading());
      throw new Error("Error fetching data");
    }
  };

  const { data, isLoading, isError } = useQuery(
    ["singleEvent", slugids],

    () => fetchData(slugids),
    {
      refetchOnWindowFocus: false,
      enabled: !_.isEmpty(slugids), // Only enable the query if slugids is not empty
      onError: () => {
        dispatch(userActions.stopLoading());
        // Handle error, for example, redirect to another page
        navigate("/");
      },
    }
  );

  const [buttLoading, setButtLoading] = useState(false);

  const [Phones, setphones] = useState("");

  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    errors,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Mobile: "",
      Email: "",
      Age: "",
      address: "",
      state: "",
      pincode: "",
      Quantity: "1",
    },

    validationSchema: bookingSchema,

    onSubmit: async (values) => {
      console.log("VALYESS", values);
      setButtLoading(true);

      const temp_data = {
        TotalAmount: data?.price || "0",
        event_id: data?._id,
        FirstName: values.FirstName,
        LastName: values.LastName,
        Mobile: values.Mobile,
        Email: values.Email,
        Age: values.Age,
        address: values.address,
        state: values.state,
        pincode: values.pincode,
        Quantity: values.Quantity,
        OrderDetails: JSON.stringify({
          title: data?.title,
          imageUrl: data?.eventImage,
          expiryDates: data?.expiryDate,
        }),
      };

      console.log("temp data", temp_data);
      await razorPay(temp_data);
      resetForm();
    },
  });

  const razorPay = async (data) => {
    console.log("RZP_DATA", data);
    try {
      const response = await axios.post(
        `${APIUrls.paymentURl}/apiv2/payment/razorpay/create-order`,
        data
      );
      console.log(response);
      setButtLoading(false);
      if (response.status === 201) {
        const { order_id } = response?.data?.response;
        //console.log("Curr",currentUser);
        const { FirstName, LastName, state, address, pincode, Email, Mobile } =
          data;

        console.log("ORDERID", order_id);
        const options = {
          //key: "rzp_test_b9jEPv7hj3KSVi",
          // Enter the Key ID generated from the Dashboard
          key: "rzp_test_u2y2ZUnD39l5aQ",
          //amount: parseInt(totalAmount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "FLODESIGN",
          description: "Event payment",
          image: "https://flodesign.in/Logo.png",
          order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
          handler: function (response) {
            console.log("RAZORPAY RESP", response);
            // console.log(response.razorpay_payment_id);
            // console.log(response.razorpay_order_id);
            // console.log(response.razorpay_signature);
            setButtLoading(false);

            return navigate(
              "/transaction/success/" + response.razorpay_payment_id
            );
          },

          prefill: {
            name: FirstName,
            email: Email,
            contact: Mobile,
          },
          theme: {
            color: "#00006B",
          },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);

          setButtLoading(false);

          return navigate(
            "/transaction/failure/" + response.error.metadata.payment_id
          );
        });

        rzp1.on("order.paid", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);

          setButtLoading(false);

          // return navigate('/paymentverify/'+response.error.metadata.payment_id)
        });

        rzp1.on("payment.capture", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);

          setButtLoading(false);
          navigate("/paymentverify/" + response.error.metadata.payment_id);
        });

        rzp1.open();
        console.log(response);
      }
    } catch (e) {
      setButtLoading(false);
      console.log(e);
      toast.error("Something went Wrong !");
    }
  };

  // useEffect(() => {

  //   fetchData(); // Call the fetchData function

  //   // Cleanup function to cancel the API request if the component unmounts
  //   return () => {
  //     // Implement cancellation logic if applicable (e.g., using AbortController)
  //   };
  // }, []);

  const options = [
    { value: "5-10", label: "5-10" },
    { value: "10-18", label: "10-18" },
    { value: "18-24", label: "18-24" },
    { value: "24-80", label: "24-80" },
  ];

  return (
    <div className=" text-[#060606] py-5 px-4  md:px-8 lg:px-8 lg:py-10 xl:px-[5rem] xl:py-20 bg-[#F6F6F6] ">
      {console.log("EVENTDATA", data)}
      <div className="booking-container ">
        <div className="card  md:gap-10 xl:gap-12 lg:flex lg:flex-row  xl:h-auto">
          <div className="w-full text-[#060606] bg-[#F6F6F6] ">
            <div className="booking-container py-5 md:px-14 pb-14 xl:px-20">
              <div className="card  md:gap-10 xl:flex xl:flex-row ">
                <img
                  src={data?.eventImage}
                  alt=""
                  className="w-full h-[400px] xl:w-[50%] xl:h-[500px] shrink-0"
                />

                <div className="xl:flex xl:flex-col xl:w-grow ">
                  <h1 className="text-[18px] text-[#060606] md:text-[38px] md:font-[700] font-raleway font-[700] py-3 tracking-[1.98px] md:tracking-[7.04px] text-left">
                    {data?.title || "NO TITLE"}
                  </h1>

                  <p className="text-[12px] text-[#060606] font-normal  md:hidden font-raleway leading-[148%] tracking-[1.32px] ">
                    {data?.description || "NO DESCRIPTION"}
                  </p>

                  <p className="text-[17px] hidden md:block font-raleway font-[400] tracking-[2.22px]">
                    {data?.description || "NO DESCRIPTION"}
                  </p>

                  <div className="hidden md:flex gap-2 py-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M15.9993 15.3337C15.1153 15.3337 14.2674 14.9825 13.6423 14.3573C13.0172 13.7322 12.666 12.8844 12.666 12.0003C12.666 11.1163 13.0172 10.2684 13.6423 9.6433C14.2674 9.01818 15.1153 8.66699 15.9993 8.66699C16.8834 8.66699 17.7313 9.01818 18.3564 9.6433C18.9815 10.2684 19.3327 11.1163 19.3327 12.0003C19.3327 12.4381 19.2465 12.8715 19.0789 13.2759C18.9114 13.6804 18.6659 14.0478 18.3564 14.3573C18.0468 14.6669 17.6794 14.9124 17.275 15.0799C16.8705 15.2474 16.4371 15.3337 15.9993 15.3337ZM15.9993 2.66699C13.524 2.66699 11.15 3.65032 9.39969 5.40066C7.64935 7.151 6.66602 9.52497 6.66602 12.0003C6.66602 19.0003 15.9993 29.3337 15.9993 29.3337C15.9993 29.3337 25.3327 19.0003 25.3327 12.0003C25.3327 9.52497 24.3494 7.151 22.599 5.40066C20.8487 3.65032 18.4747 2.66699 15.9993 2.66699Z"
                        fill="#060606"
                      />
                    </svg>
                    <p className="text-[20px] md:text-[17px] hidden md:block font-raleway font-[400] tracking-[2.22px]">
                      {data?.location || "NO location"}
                    </p>
                  </div>

                  <div className=" date-container my-2 flex gap-10 md:gap-6 items-center py-[8px] px-[24px] border-[1px] border-[#808080]">
                    {console.log(data?.expiryDate)}
                    {console.log(moment(data?.expieryDate).format("Do MMM,"))}
                    <p className="text-[16px] md:text-[24px] font-raleway font-medium">
                      {moment(data?.expiryDate).format("Do MMM,")}
                    </p>
                    <p className="text-[16px] md:text-[24px] font-raleway font-medium">
                      {moment(data?.expiryDate).format("HH:mm A")}
                    </p>
                  </div>

                  <form onSubmit={handleSubmit} className="mt-4 md:mt-7">
                    <p className="text-[12px] text-[#060606] md:text-[24px] font-raleway font-bold leading-[99%]  tracking-[1.32px] pt-2">
                      Book Now!{" "}
                    </p>

                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-3 xl:gap-4">
                      <div className=" flex flex-col my-3">
                        <input
                          type="text"
                          placeholder="First Name"
                          name="FirstName"
                          value={values.FirstName}
                          onChange={handleChange}
                          className="p-[16px] text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full  mb-0 md:placeholder:text-[#060606]  outline-none  border border-solid border-[#808080] font-raleway font-[400] tracking-[1.32px]"
                        />
                        {errors.FirstName && touched.FirstName ? (
                          <div className="error text-red-700 text-[12px] mt-0 font-[600] pl-2 md:text-[14px]">
                            *{errors.FirstName}
                          </div>
                        ) : null}
                      </div>

                      <div className="flex flex-col my-3">
                        <input
                          type="text"
                          placeholder="Last Name"
                          name="LastName"
                          value={values.LastName}
                          onChange={handleChange}
                          className="p-[16px] text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full mb-0 md:placeholder:text-[#060606]   outline-none border border-solid border-[#808080] font-raleway font-[400] tracking-[1.32px]"
                        />

                        {errors.LastName && touched.LastName ? (
                          <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                            *{errors.LastName}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex flex-col my-3">
                        <PhoneInput
                          type="text"
                          name="Mobile"
                          value={values.Mobile}
                          onChange={(phone) => setFieldValue("Mobile", phone)}
                          placeholder="Mobile Number"
                          country="in"
                          inputClass="p-[16px] text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full mb-0  md:placeholder:text-[#060606]  outline-none border border-solid border-[#808080]  text-[12px] md:text-[15px] lg:text-[20px] font-raleway font-[400] tracking-[1.32px]"
                          inputStyle={{
                            width: "100%", // Ensure the width is 100% of the container
                            height: "50px",
                            fontSize: "16px",
                            borderRadius: "0px",
                            // border: "1px solid #808080"
                          }}
                        />
                        {/* <input
                                          type='text'
                                          name='Mobile'
                                          value={values.Mobile}
                                          onChange={handleChange}
                                          placeholder='Mobile Number'
                                          className='p-[16px] w-full h-[44px] lg:h-[62px] mb-0  md:placeholder:text-[#060606]  outline-none border border-solid border-[#808080]  text-[12px] md:text-[15px] lg:text-[20px] font-raleway font-[400] tracking-[1.32px]'
                                        /> */}
                        {errors.Mobile && touched.Mobile ? (
                          <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                            *{errors.Mobile}
                          </div>
                        ) : null}
                      </div>

                      <div className="flex flex-col my-3">
                        <input
                          type="text"
                          placeholder="Email Id"
                          name="Email"
                          value={values.Email}
                          onChange={handleChange}
                          className="p-[16px] text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full mb-0 md:placeholder:text-[#060606]  outline-none border border-solid border-[#808080] font-raleway font-[400] tracking-[1.32px]"
                        />
                        {errors.Email && touched.Email ? (
                          <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                            *{errors.Email}
                          </div>
                        ) : null}
                      </div>

                      <div className="flex flex-col my-3 w-full">
                        {console.log(errors)}
                        {/* 
                                        <Select
                                          id='countries_disabled'
                                          name='Age'
                                          value={values.Age}
                                          onChange={handleChange}


                                          options={options}
                                          placeholder='Age Group'
                                          styles={{
                                            control: (provided) => ({
                                              display: "flex",

                                              outline: "none",

                                              width: "100%",
                                              height: "44px",
                                              border: "1px solid #808080",
                                              fontFamily: "Raleway",
                                              fontSize: "12px",
                                              color: "#808080",
                                              fontWeight: "400",
                                              "@media (min-width: 7680px)": {
                                                color: "#060606",
                                              },

                                              "@media (min-width:920px)": {
                                                fontSize: "20px",
                                                height: "62px",
                                              },
                                            }),

                                            placeholder: (styled) => ({
                                              ...styled,
                                              color: "#808080",

                                              "@media (min-width:768px)": {
                                                color: "#060606",
                                              },
                                            }),

                                            indicatorSeparator: (provided) => ({
                                              display: "none",
                                            }),
                                            dropdownIndicator: (provided) => ({
                                              // Adjust as needed
                                              paddingRight: "16px",

                                              color: "#666666",
                                            }),
                                          }}
                                        />

                                        {errors.Age && touched.Age ? (
                                          <div className='error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]'>
                                            *{errors.Age}
                                          </div>
                                        ) : null} */}

                        <div className="border border-solid border-[#808080]">
                          <select
                            id="countries_disabled"
                            name="Age"
                            value={values.Age}
                            onChange={handleChange}
                            style={{
                              borderRight: "20px solid transparent",
                            }}
                            className="pl-[16px] pr-[16px] text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full mb-0 outline-none border-none font-raleway font-[400] tracking-[1.32px] cursor-pointer"
                          >
                            <option disabled value="">
                              Age Group
                            </option>
                            <option value="5-10">5-10</option>
                            <option value="10-18">10-18</option>
                            <option value="24-28">24-28</option>
                            <option value="28-80">28-80</option>
                          </select>
                        </div>
                        {errors.Age && touched.Age ? (
                          <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                            *{errors.Age}
                          </div>
                        ) : null}
                      </div>

                      <div className="flex flex-col my-3">
                        <input
                          type="text"
                          name="address"
                          placeholder="Address"
                          value={values.address}
                          onChange={handleChange}
                          className="p-[16px] text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full mb-0 md:placeholder:text-[#060606] outline-none border border-solid border-[#808080] font-raleway font-[400] tracking-[1.32px]"
                        />

                        {errors.address && touched.address ? (
                          <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                            *{errors.address}
                          </div>
                        ) : null}
                      </div>

                      <div className="flex flex-col my-3">
                        <input
                          type="text"
                          name="state"
                          placeholder="State"
                          value={values.state}
                          onChange={handleChange}
                          className="p-[16px] text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full mb-0 md:placeholder:text-[#060606] outline-none border border-solid border-[#808080] font-raleway font-[400] tracking-[1.32px]"
                        />

                        {errors.state && touched.state ? (
                          <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                            *{errors.state}
                          </div>
                        ) : null}
                      </div>

                      <div className="flex flex-col my-3">
                        <input
                          type="text"
                          placeholder="Pincode"
                          name="pincode"
                          value={values.pincode}
                          onChange={handleChange}
                          className="p-[16px] text-[14px] sm:text-[16px] h-[45px] md:h-[50px] w-full mb-0 md:placeholder:text-[#060606] outline-none border border-solid border-[#808080] font-raleway font-[400] tracking-[1.32px]"
                        />
                        {errors.pincode && touched.pincode ? (
                          <div className="error text-red-700 text-[12px] font-[600] pl-2 md:text-[14px]">
                            *{errors.pincode}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    <div className="prize-container flex flex-row justify-between items-center ">
                      <div>
                        <p className="text-[24px] md:text-[40px] font-[700] font-raleway tracking-[2.64px] py-2 text-[#060606]">
                          ₹{data?.price * values?.Quantity || "0"}
                        </p>
                      </div>

                      <div className="flex gap-3 justify-center items-center md:py-2">
                        <p className="text-[16px] md:text-[24px] font-[700] font-raleway tracking-[1.74px] ">
                          Qty
                        </p>

                        <select
                          id="countries_disabled"
                          name="Quantity"
                          className=" h-[32px] w-[48px] md:w-[50px] md:h-[30px] xl:w-[50px] xl:h-[50px] outline-none rounded-md border border-solid border-[#808080] font-bold md:px-1    text-[1.2rem]"
                          onChange={handleChange}
                          defaultValue={1}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </select>
                        <button
                          type="submit"
                          disabled={buttLoading}
                          style={{
                            boxShadow:
                              "0px 10px 30px 0px rgba(13, 13, 13, 0.15)",
                          }}
                          className="bg-[#61D4FB] w-[120px] h-[38px] lg:w-[140px] lg:h-[50px] xl:w-[180px] xl:h-[55px] xl:text-[16px]  flex flex-row justify-center items-center text-[#101010] text-[13px] font-bold"
                        >
                          {buttLoading ? (
                            <BeatLoader color="#fff" />
                          ) : (
                            "Book Now"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                <div>
                  <form
                    action={`${APIUrls.paymentURl}/apiv2/payment/razorpay/create-order`}
                    ref={formPay}
                    method="POST"
                  >
                    <input
                      type="hidden"
                      name="TotalAmount"
                      value={data?.price || "0"}
                    ></input>
                    <input
                      type="hidden"
                      name="event_id"
                      value={data?._id}
                    ></input>
                    <input
                      type="hidden"
                      name="FirstName"
                      value={values.FirstName}
                      required
                    />
                    <input
                      type="hidden"
                      name="LastName"
                      value={values.LastName}
                      required
                    />
                    <input
                      type="hidden"
                      name="Mobile"
                      value={values.Mobile}
                      required
                    />
                    <input
                      type="hidden"
                      name="Email"
                      value={values.Email}
                      required
                    />
                    <input
                      type="hidden"
                      name="Age"
                      value={values.Age}
                      required
                    />
                    <input
                      type="hidden"
                      name="address"
                      value={values.address}
                      required
                    />
                    <input
                      type="hidden"
                      name="state"
                      value={values.state}
                      required
                    />
                    <input
                      type="hidden"
                      name="pincode"
                      value={values.pincode}
                      required
                    />
                    <input
                      type="hidden"
                      name="Quantity"
                      value={values.Quantity}
                      required
                    />

                    <input
                      type="hidden"
                      name="OrderDetails"
                      value={JSON.stringify({
                        title: data?.title,
                        imageUrl: data?.event,
                        expieryDates: data?.expieryDate,
                      })}
                      required
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //             <div>
  //               <form
  //                 action='http://localhost:4000/apiv2/payment/airpay-post'
  //                 ref={formPay}
  //                 method='POST'>
  //                 <input
  //                   type='hidden'
  //                   name='TotalAmount'
  //                   value={data?.price || "0"}></input>
  //                 <input
  //                   type='hidden'
  //                   name='event_id'
  //                   value={data?._id}></input>
  //                 <input
  //                   type='hidden'
  //                   name='FirstName'
  //                   value={values.FirstName}
  //                   required
  //                 />
  //                 <input
  //                   type='hidden'
  //                   name='LastName'
  //                   value={values.LastName}
  //                   required
  //                 />
  //                 <input
  //                   type='hidden'
  //                   name='Mobile'
  //                   value={values.Mobile}
  //                   required
  //                 />
  //                 <input
  //                   type='hidden'
  //                   name='Email'
  //                   value={values.Email}
  //                   required
  //                 />
  //                 <input
  //                   type='hidden'
  //                   name='Age'
  //                   value={values.Age}
  //                   required
  //                 />
  //                 <input
  //                   type='hidden'
  //                   name='address'
  //                   value={values.address}
  //                   required
  //                 />
  //                 <input
  //                   type='hidden'
  //                   name='state'
  //                   value={values.state}
  //                   required
  //                 />
  //                 <input
  //                   type='hidden'
  //                   name='pincode'
  //                   value={values.pincode}
  //                   required
  //                 />
  //                 <input
  //                   type='hidden'
  //                   name='Quantity'
  //                   value={values.Quantity}
  //                   required
  //                 />
  //               </form>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </div>
};

export default Booking;
