let baseUrl = "http://localhost:4000";



const type  = "PROD"

if(type === "PROD"){

    baseUrl = "https://apiv2.flodesign.in"
}

else { 

    baseUrl = "http://localhost:4000"
}


export const APIUrls ={
    contactUs:`${baseUrl}/addnewticket`,
    fetchEvents:`${baseUrl}/apiv2/fetch-events`,
    fetchSingle:`${baseUrl}/apiv2/fetch-single`,
    sendPayment:`${baseUrl}/apiv2/payment/airpay-post`,
    fetcShops:`${baseUrl}/apiv2/fetch/paintings`,
    paymentURl:`${baseUrl}`,
    paymentStatus:`${baseUrl}/apiv2/payment/airpay/verify-order`,
    postEnquiry:`${baseUrl}/apiv2/create-enquery`
    
  
}