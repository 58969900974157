import {configureStore,combineReducers,applyMiddleware} from '@reduxjs/toolkit';
import thunk from 'redux-thunk'

import { userSlice } from './IndexReducer'



// Combine the reducers
const rootReducer = combineReducers({
  
    // Add other reducers here
    indexReducer: userSlice.reducer,
  });

const store = configureStore({
    reducer: rootReducer,
    middleware: [thunk], // Apply Redux Thunk middleware
});


export const userActions = userSlice.actions;

export default store;