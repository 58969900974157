import React, { useEffect, useState, useRef } from "react";

// import Zoom from "react-medium-image-zoom";
// import "react-medium-image-zoom/dist/styles.css";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

import Popup from "reactjs-popup";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import Footer from "./Footer";
import aboutimg from "../assets/aboutimg.png";
import trophy from "../assets/award.png";
import paint from "../assets/paint.png";
import book from "../assets/book.png";
import prize from "../assets/prize.png";
import color from "../assets/color.png";
import aboutlogo from "../assets/aboutlogo.png";
import desktopaboutimg from "../assets/aboutnavimg.png";
import desktopaboutlogo from "../assets/desktopaboutlogo.png";
import fabricPainting from "../assets/about/fabric-painting.svg";
import neonPaint from "../assets/workshop/neon-paint.svg";
//import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import workshop1 from "../assets/workshop/workshop1.png";
import workshop2 from "../assets/workshop/workshop2.png";
import workshop3 from "../assets/workshop/workshop3.png";
import workshop4 from "../assets/workshop/workshop4.png";
import workshop5 from "../assets/workshop/workshop5.png";
import workshop6 from "../assets/workshop/workshop6.png";

import grp1 from "../assets/mdgroup/group1.png";
import grp2 from "../assets/mdgroup/group2.png";
import grp3 from "../assets/mdgroup/group3.png";
import grp4 from "../assets/mdgroup/group4.png";
import grp5 from "../assets/mdgroup/group5.png";
import grp6 from "../assets/mdgroup/group6.png";
import grp8 from "../assets/mdgroup/group8.png";
import grp9 from "../assets/mdgroup/group9.png";
import grp10 from "../assets/mdgroup/group10.png";

import neon1 from "../assets/gallery/neon1.jpg";
import neon2 from "../assets/gallery/neon2.jpg";
import neon3 from "../assets/gallery/neon3.jpg";
import neon4 from "../assets/gallery/neon4.jpg";
import neon5 from "../assets/gallery/neon5.jpg";
import neon6 from "../assets/gallery/neon6.jpg";
import neon7 from "../assets/gallery/neon7.jpg";
import neon8 from "../assets/gallery/neon8.jpg";
import neon9 from "../assets/gallery/neon9.jpg";
import neon10 from "../assets/gallery/neon10.jpg";
import bridal1 from "../assets/gallery/bridal1.jpg";
import bridal2 from "../assets/gallery/bridal2.jpg";
import bridal3 from "../assets/gallery/bridal3.jpg";
import bridal4 from "../assets/gallery/bridal4.jpg";
import bridal5 from "../assets/gallery/bridal5.jpg";
import bridal6 from "../assets/gallery/bridal6.jpg";
import bridal7 from "../assets/gallery/bridal7.jpg";
import womensDay1 from "../assets/gallery/womens-day1.jpg";
import womensDay2 from "../assets/gallery/womens-day2.jpg";
import womensDay3 from "../assets/gallery/womens-day3.jpg";
import womensDay4 from "../assets/gallery/womens-day4.jpg";
import womensDay5 from "../assets/gallery/womens-day5.jpg";
import womensDay6 from "../assets/gallery/womens-day6.jpg";
import womensDay7 from "../assets/gallery/womens-day7.jpg";
import womensDay8 from "../assets/gallery/womens-day8.jpg";
import random1 from "../assets/gallery/random1.jpg";
import random2 from "../assets/gallery/random2.jpg";
import random3 from "../assets/gallery/random3.jpg";
import random4 from "../assets/gallery/random4.jpg";
import random5 from "../assets/gallery/random5.jpg";
import random6 from "../assets/gallery/random6.jpg";
import random7 from "../assets/gallery/random7.jpg";

import therapeuticArt from "../assets/therapeuticArt.svg";
import privateEvent from "../assets/privateEvent.svg";
import sippaint from "../assets/about/sippaint.svg";
import commissionedArt from "../assets/workshop/commissioned-art.svg";

import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Grid,
} from "swiper/modules";

import right from "../assets/CaretRight.png";
import down from "../assets/down.png";
import WorkshopModal from "./WorkshopModal";

const PRIVATE_EVENTS = [
  bridal1,
  bridal2,
  bridal3,
  bridal4,
  bridal5,
  bridal6,
  bridal7,
];

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [headerName, setHeaderName] = useState("");
  const [imgArr, setImgArr] = useState([]);

  const [accordian1, setAccordian1] = useState(true);
  const [accordian2, setAccordian2] = useState(true);
  const [accordian3, setAccordian3] = useState(true);
  const [accordian4, setAccordian4] = useState(true);
  const [accordian5, setAccordian5] = useState(true);
  const [accordian6, setAccordian6] = useState(true);

  const [selectedImage, setSelectedImage] = useState({ image: "", id: "" });

  const [area, setArea] = useState({ height: 300, width: 300 });

  const pageOneImagesList = [
    neon1,
    womensDay1,
    bridal1,
    random7,
    neon9,
    bridal2,
    womensDay2,
    random3,
    random5,
    womensDay4,
    womensDay8,
  ];
  const pageTwoImagesList = [
    random6,
    neon8,
    neon10,
    neon7,
    neon6,
    bridal3,
    bridal4,
    womensDay7,
    bridal5,
    bridal7,
  ];
  const pageThreeImagesList = [
    womensDay3,
    neon3,
    bridal6,
    neon5,
    womensDay6,
    neon4,
    random4,
    neon2,
    random2,
    womensDay5,
    random1,
    neon6,
  ];
  const imagesList = [grp10, grp2, grp3, grp6, grp8, grp9, grp4, grp5, grp1];

  console.log(selectedImage);

  const setImage = (action) => {
    if (action === "forward") {
      if (selectedImage.id >= imagesList.length - 1) {
        setSelectedImage({ image: imagesList[0], id: 0 });
      } else {
        setSelectedImage({
          image: imagesList[selectedImage.id + 1],
          id: selectedImage.id + 1,
        });
      }
    }

    if (action === "backward") {
      if (selectedImage.id <= 0) {
        setSelectedImage({
          image: imagesList[imagesList.length - 1],
          id: imagesList.length - 1,
        });
      } else {
        setSelectedImage({
          image: imagesList[selectedImage.id - 1],
          id: selectedImage.id - 1,
        });
      }
    }
  };

  const handleDoubleClick = () => {
    setArea((prevstate) => ({
      height: prevstate.height + 100,
      width: prevstate.width + 100,
    }));
  };

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#" + "gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();
    // let secondaryZoomLevel = (zoomLevelObject) => {
    //   // zoomLevelObject is instance of ZoomLevel class
    //   console.log('Element size:', zoomLevelObject.elementSize);
    //   console.log('Pan area size (viewport minus padding):', zoomLevelObject.panAreaSize);
    //   console.log('Item index:', zoomLevelObject.index);
    //   console.log('Item data:', zoomLevelObject.itemData);
    //   // return desired zoom level
    //   return 1;
    // }
    // secondaryZoomLevel();
    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  const showModalHandler = (header, arr) => {
    setShowModal(true);
    setHeaderName(header);
    setImgArr(arr);
  };

  return (
    <div className={`bg-[#F6F6F6] h-full`}>
      <div className="py-8 md:py-15  px-4 md:px-10 max-w-[1300px] xl:mx-auto flex flex-col">
        {/* <h2 className="font-Raleway text-[32px] md:text-[80px] text-[#060606] italic font-extrabold leading-[99%] tracking-[3.52px] self-center mb-[48px]"> */}
        <h2 className="text-center text-[32px] md:text-[80px]  font-raleway py-7 text-[#060606] tracking-wider italic font-extrabold bg-gradient-to-r from-blue-900 to-blue-300 text-transparent bg-clip-text">
          “About Us”
        </h2>
        {/* <div className="img md:hidden ">
          <img
            src={aboutimg}
            alt="img"
            className="w-full h-[256px] object-cover"
          />
        </div> */}

        {/* small devices about container */}
        {/* <div className="about-content md:hidden">
          <h2 className="text-left text-[32px] md:text-[80px] text-[#0074BE] font-medium monarda pt-5">
            Manoghnya Pachipala
          </h2>

          <div className="flex items-center">
            <span className="border-[1px] border-[#000000] w-[34px]"></span>
            <p className="text-[18px] md:text-[32px] text-[#060606] font-medium p-2 monarda">
              Founder
            </p>
            <span className="border-[1px] border-[#000000] w-[32px]"></span>
          </div>

          <p className="text-[12px] font-[400] font-raleway py-2 tracking-wider">
            Art has always been a part of my life since childhood, thanks to my
            artist mother. Winning my first art competition at age 5 ignited a
            lifelong passion. I've eagerly taken part in numerous art
            competitions in India and abroad, exploring various styles and
            themes.
          </p>

          <p className="text-[12px] font-[400] font-raleway py-2 tracking-wider">
            At just 7, I hosted my own solo exhibition, showcasing 30
            meticulously crafted paintings. This experience enhanced my skills
            and fostered a sense of achievement and self-confidence. The
            exhibition was a pivotal moment, motivating me to push boundaries
            and venture into new artistic realms.
          </p>
        </div> */}

        {/* large devices about context */}

        {/* <div className="about-name-container hidden md:flex md:flex-col  md:w-full">
          <div className="flex flex-col ">
            <h2 className="text-left text-[32px] md:text-[50px] xl:text-[80px] text-[#0074BE] font-medium pt-5 monarda">
              Manoghnya Pachipala
            </h2>

            <div className="flex items-center">
              <span className="border-[1px] border-[#000000] w-[34px]"></span>
              <p className="text-[18px] md:text-[32px] text-[#060606] font-medium p-2 monarda">
                Founder
              </p>
              <span className="border-[1px] border-[#000000] w-[32px]"></span>
            </div>
          </div>

          <div className="img-container flex flex-col-reverse xl:flex-row gap-[32px] my-3 justify-start">
            <div className="text-side md:w-auto xl:w-[70%] flex flex-col gap-2">
              <li className="text-[20px] md:text-[16px] font-raleway font-[400] text-[#060606]   tracking-[2.2px]">
                Art has been integral to my life since a young age, influenced
                by my artist mother.
              </li>

              <li className="text-[20px] md:text-[16px] font-raleway font-[400] text-[#060606]  tracking-[2.2px]">
                Won my first art competition at age 5, sparking a lifelong
                passion for art.
              </li>

              <li className="text-[20px] md:text-[16px] font-raleway font-[400] text-[#060606]  tracking-[2.2px]">
                {" "}
                Actively participated in numerous art competitions in India and
                internationally.
              </li>

              <li className="text-[20px] md:text-[16px]  font-raleway font-[400] text-[#060606] tracking-[2.2px]">
                {" "}
                Explored diverse styles, themes, and forms of artistic
                expression.
              </li>

              <li className="text-[20px] md:text-[16px] font-raleway font-[400] text-[#060606] tracking-[2.2px]">
                Hosted a solo art exhibition at age 7, displaying 30 carefully
                crafted paintings.
              </li>

              <li className="text-[20px] md:text-[16px] font-raleway font-[400] text-[#060606] tracking-[2.2px]">
                Collaboration with talented artists on projects like the
                creation of the longest painted flag.
              </li>

              <li className="text-[20px] md:text-[16px] font-raleway font-[400] text-[#060606]  tracking-[2.2px]">
                Contributed to the Horlicks WizKids competition, celebrating
                artistic talent and growth.
              </li>

              <li className="text-[20px] md:text-[16px] font-raleway font-[400] text-[#060606] tracking-[2.2px]">
                Grateful to my mother for introducing me to art and nurturing my
                creativity.
              </li>

              <li className="text-[20px] md:text-[16px] font-raleway font-[400] text-[#060606] tracking-[2.2px]">
                Committed to artistic excellence and inspiring others on their
                own artistic journeys.
              </li>
            </div>

            <div className="img-container md:w-full xl:w-[30%]">
              <img
                src={desktopaboutimg}
                alt=""
                className=" h-[510px] w-full object-cover static"
              />
            </div>
          </div>
        </div> */}

        {/* <div className='flex flex-col md:flex-row md:flex-wrap md:justify-around   md:self-start  md:gap-[2rem] xl:gap-[3rem] gap-[1.5rem] mt-[1rem] md:mt-[64px] text-[#060606]'>
          <div className='flex flex-row md:justify-start items-center  gap-[1rem] md:gap-[2rem] '>
            <img
              src={trophy}
              className='h-[48px] w-[48px] md:h-[60px] md:w-[60px] xl:h-[84px] xl:w-[84px] '
            />
            <div className='flex flex-col '>
              <h2 className='text-[16px] md:text-[18px] xl:text-[24px] font-bold font-raleway tracking-wider'>
                The Balaratna Award in Painting
              </h2>
              <p className='text-[12px] md:text-[18px] xl:text-[20px] font-[400] font-raleway tracking-wider'>
                - Recognized for Exceptional Talent
              </p>
            </div>
          </div> 
          <div className='flex flex-row items-center gap-[1rem] md:gap-[2rem] '>
            <img
              src={trophy}
              className='h-[48px] w-[48px] md:h-[60px] md:w-[60px] xl:h-[84px] xl:w-[84px] '
            />
            <div className='flex flex-col '>
              <h2 className='text-[16px] md:text-[18px] xl:text-[24px] font-bold font-raleway tracking-wider'>
                The Balaratna Award in Painting
              </h2>
              <p className='text-[12px] md:text-[18px] xl:text-[20px] font-[400] font-raleway tracking-wider'>
                - Recognized for Exceptional Talent
              </p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-[1rem] md:gap-[2rem] '>
            <img
              src={trophy}
              className='h-[48px] w-[48px] md:h-[60px] md:w-[60px] xl:h-[84px] xl:w-[84px] '
            />
            <div className='flex flex-col '>
              <h2 className='text-[16px] md:text-[18px] xl:text-[24px] font-bold font-raleway tracking-wider'>
                The Balaratna Award in Painting
              </h2>
              <p className='text-[12px] md:text-[18px] xl:text-[20px] font-[400] font-raleway tracking-wider'>
                - Recognized for Exceptional Talent
              </p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-[1rem] md:gap-[2rem] '>
            <img
              src={trophy}
              className='h-[48px] w-[48px] md:h-[60px] md:w-[60px] xl:h-[84px] xl:w-[84px] '
            />
            <div className='flex flex-col '>
              <h2 className='text-[16px] md:text-[18px] xl:text-[24px] font-bold font-raleway tracking-wider'>
                The Balaratna Award in Painting
              </h2>
              <p className='text-[12px] md:text-[18px] xl:text-[20px] font-[400] font-raleway tracking-wider'>
                - Recognized for Exceptional Talent
              </p>
            </div>
          </div>
          <div className='flex flex-row items-center gap-[1rem] md:gap-[2rem] '>
            <img
              src={trophy}
              className='h-[48px] w-[48px] md:h-[60px] md:w-[60px] xl:h-[84px] xl:w-[84px] '
            />
            <div className='flex flex-col '>
              <h2 className='text-[16px] md:text-[18px] xl:text-[24px] font-bold font-raleway tracking-wider'>
                The Balaratna Award in Painting
              </h2>
              <p className='text-[12px] md:text-[18px] xl:text-[20px] font-[400] font-raleway tracking-wider'>
                - Recognized for Exceptional Talent
              </p>
            </div>
          </div>
        </div>  */}

        {/* <div className="mt-5 md:p-20 categort-award-section flex flex-col mx-4 md:flex-row md:justify-around md:gap-8 md:my-8 py-10 text-[#060606]">
          <div className="first-category xl:mt-5 ">
            <div className="mt-4 one  flex flex-row items-center gap-[1.5rem] md:gap-8 xl:w-auto  ">
              <img
                src={trophy}
                alt=""
                className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
              />
              <div className="text md:mt-3">
                <h2 className="text-[1rem]  xl:text-[20px] font-bold font-raleway tracking-wider">
                  The Balaratna Award in Painting
                </h2>
                <p className="text-[12px]  xl:text-[18px] font-[400] font-raleway tracking-wider">
                  - Recognized for Exceptional Talent
                </p>
              </div>
            </div>

            <div className="mt-4 xl:mt-10 flex flex-row items-center gap-[1.5rem] md:gap-8 my-4 md:w-[300px] xl:w-auto  ">
              <div>
                <img
                  alt=""
                  className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
                  src={horlicksWizard}
                />
              </div>

              <div className="text md:mt-3">
                <h2 className="text-[1rem]  xl:text-[20px] font-bold font-raleway ">
                  Horlicks WizKids Competition
                </h2>
                <p className="text-[12px]  xl:text-[18px] font-[400] font-raleway tracking-wider">
                  - Three-Time Consecutive Winner
                </p>
              </div>
            </div>

            <div className="mt-4 xl:mt-10    flex flex-row items-center  gap-[1.5rem] md:gap-8 my-4   md:w-[300px] xl:w-auto  ">
              <img
                alt=""
                className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
                src={limcaBook}
              />
              <div className="text md:mt-3">
                <h2 className="text-[1rem]  xl:text-[20px] font-bold font-raleway tracking-wider">
                  Limca Book of Records
                </h2>
                <p className="text-[12px]  xl:text-[18px] font-[400] font-raleway tracking-wider">
                  Contribution to the Longest Painted Flag
                </p>
              </div>
            </div>
          </div>

          <div className="second-category md:mb-10">
            <div className="mt-4 xl:mt-10   flex flex-row items-center  gap-[1.5rem] md:gap-8 my-4   md:w-[300px] xl:w-auto  ">
              <img
                alt=""
                className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
                src={medal}
              />
              <div className="text md:mt-3">
                <h2 className="text-[1rem]  xl:text-[20px] font-bold font-raleway tracking-wider">
                  Multiple Art Competition Wins
                </h2>
                <p className="text-[12px] font-[400] xl:text-[18px]  font-raleway tracking-wider">
                  - Across India and Internationally
                </p>
              </div>
            </div>

            <div className="mt-4 xl:mt-10 md:mb-10 one  flex flex-row items-center  gap-[1.5rem] md:gap-8 my-4   md:w-[300px] xl:w-auto  ">
              <img
                alt=""
                className="w-[48px] h-[48px] md:w-[64px] md:h-[64px] xl:w-[84px] xl-[84px]"
                src={artExhibition}
              />
              <div className="text md:mt-3">
                <h2 className="text-[1rem]  xl:text-[20px] font-bold font-raleway tracking-wider">
                  Solo Art Exhibition at Age 7
                </h2>
                <p className="text-[12px]  xl:text-[18px] font-[400] font-raleway tracking-wider">
                  - Showcasing 30 Captivating Paintings
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* Small device container */}
        {/* <hr className=" border-[1px] border-[#808080] w-[96px] mx-auto mt-10 md:hidden " /> */}

        <div className="">
          <div className="about-logo mx-4 md:mx-0 md:hidden">
            <img
              src={aboutlogo}
              alt=""
              className="w-full h-[200px] sm:h-[256px] object-cover"
            />
          </div>

          <div className="query-container mx-1 md:mx-0 md:hidden">
            <div className="one mt-5">
              <h2 className="text-[18px] lg:text-[24px] font-bold font-raleway text-left my-3 tracking-wider">
                Who are we?
              </h2>
              {/* leading-7 tracking-[1.1px]  md:tracking-[2.2px] */}
              <p className="text-[13px] font-normal font-raleway my-2 leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
                Welcome to Flo Design, a company dedicated to providing unique
                and fun-filled art-themed workshops at various venues. Our
                primary goal is to introduce a concept that is tailored to your
                venue,{" "}
              </p>
              <p className="text-[13px] font-normal font-raleway my-2 leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
                {" "}
                Providing unique and fun-filled art workshops tailored to your
                venue. Partner with us for weekly events, leveraging sales,
                marketing, and social media to raise brand awareness. Four weeks
                of impactful sessions, limited to 15-25 students aged 10 to 100.
                Materials provided, systematic teaching approach for a better
                artistic understanding.
              </p>
            </div>

            <div className="two mt-5 ">
              <h2 className="text-[18px] font-bold font-raleway text-left my-3 tracking-wider">
                What We Offer
              </h2>
              <p className="text-[13px] font-normal font-raleway my-2 leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
                <strong>Diverse Workshops:</strong> Our workshops cover a wide
                range of artistic disciplines to cater to different interests
                and skill levels.
              </p>
              <p className="text-[13px] font-normal font-raleway my-2 leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
                <strong>Expert Instruction:</strong> Passionate artists with
                years of experience provide personalized guidance and
                encouragement to help you unlock your creative potential.
              </p>
              <p className="text-[13px] font-normal font-raleway my-2 leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
                <strong>Community Engagement:</strong> Beyond just workshops, we
                foster a vibrant community of artists and art enthusiasts.
              </p>
              <p className="text-[13px] font-normal font-raleway my-2 leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
                <strong>Fun and Relaxation:</strong> Our workshops offer a fun
                and relaxing atmosphere where you can unwind, unleash your
                imagination, and enjoy the process of creating something
                beautiful.
              </p>
            </div>
            <div className="two mt-5 ">
              <h2 className="text-[18px] font-bold font-raleway text-left my-3 tracking-wider">
                What makes us special?
              </h2>
              <p className="text-[13px] font-normal font-raleway my-2 leading-7 tracking-[1.1px]  md:tracking-[2.2px]">
                What sets us apart and makes us attractive to our target
                customers is our pioneering approach in Hyderabad. As one of the
                first companies to focus on creating an experiential learning
                event, we have gained significant recognition in a relatively
                short period. Our events are known for their seamless sequence
                and engaging atmosphere, encouraging participants to socialize
                and fostering a desire to return for more, often resulting in
                larger crowds.
              </p>
            </div>
            <br></br>
          </div>
        </div>

        {/* large device container */}
        <div className="mt-5 query-container hidden md:flex md:justify-center md:items-center md:gap-10  ">
          <div className="img">
            <img src={desktopaboutlogo} alt="" className="w-[600px]" />
          </div>

          <div className="text-container w-auto">
            <h2 className="text-[24px] font-raleway font-bold text-[#060606] py-3">
              Who are we?
            </h2>
            <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
              Welcome to Flo Design, a company dedicated to providing unique and
              fun-filled art-themed workshops at various venues. Our primary
              goal is to introduce a concept that is tailored to your venue,
              ultimately increasing footfall and attracting new customers. We
              firmly believe in offering enjoyable learning experiences that
              leave a lasting impression.
            </p>

            <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
              At Flo Design, we aim to establish a partnership with your
              establishment to conduct these conceptual events on a weekly
              basis. By leveraging both your sales and marketing team and our
              existing customer base, as well as utilizing social media
              platforms to raise brand awareness, we will work together to
              maximize event attendance. For optimal impact, we recommend
              consistently hosting the event over the course of four weeks.
            </p>

            <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
              Each workshop begins with an introduction to the session, where we
              unveil the specific painting that participants will create. We
              provide all the necessary materials, ensuring a hassle-free
              experience for everyone. Our workshops are designed to teach the
              painting in a systematic manner, promoting a better understanding
              of the artistic process. To maintain a conducive learning
              environment, we limit the number of students to a maximum of 25
              and a minimum of 15, with an age range from 10 to 100 years.
            </p>
          </div>
        </div>

        {/* large device container */}
        <div className="large hidden md:block md:mt-[48px]">
          <h2 className="text-[24px] font-raleway font-bold text-[#060606] py-3">
            What We Offer
          </h2>
          <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
            <strong>Diverse Workshops:</strong> Our workshops cover a wide range
            of artistic disciplines to cater to different interests and skill
            levels.
          </p>
          <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
            <strong>Expert Instruction:</strong> Passionate artists with years
            of experience provide personalized guidance and encouragement to
            help you unlock your creative potential.
          </p>
          <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
            <strong>Community Engagement:</strong> Beyond just workshops, we
            foster a vibrant community of artists and art enthusiasts.
          </p>
          <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
            <strong>Fun and Relaxation:</strong> Our workshops offer a fun and
            relaxing atmosphere where you can unwind, unleash your imagination,
            and enjoy the process of creating something beautiful.
          </p>
        </div>
        <div className="large hidden md:block md:mt-[48px]">
          <h2 className="text-[24px] font-raleway font-bold text-[#060606] py-3">
            What makes us special?
          </h2>
          <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
            What sets us apart and makes us attractive to our target customers
            is our pioneering approach in Hyderabad. As one of the first
            companies to focus on creating an experiential learning event, we
            have gained significant recognition in a relatively short period.
          </p>
          <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
            Our primary goal is to introduce a concept that is tailored to your
            venue, ultimately increasing footfall and attracting new customers.
          </p>
          <p className="text-[16px] font-raleway font-medium py-2 tracking-[2.2px]">
            We provide all the necessary materials, ensuring a hassle-free
            experience for everyone. Our events are known for their seamless
            sequence and engaging atmosphere, encouraging participants to
            socialize and fostering a desire to return for more, often resulting
            in larger crowds.
          </p>
        </div>

        {/* workshop card container */}
        <div className="workshop-div-container md:mt-[48px]">
          <h2 className="text-[18px] md:text-[24px]  font-bold font-raleway tracking-wider my-4 md:my-6 mx-2 md:mx-0">
            Workshops we offer
          </h2>

          <div className="workshop-container grid gap-2 sm:gap-4 mx-1 md:mx-0  md:gap-16 grid-cols-2 md:grid-cols-2 lg:grid-cols-3 lg:gap-14">
            <div
              onClick={() => showModalHandler("Sip & Paint")}
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="card1 bg-[#F6F6F6] p-[8px] sm:p-[16px] lg:p-[24px] w-full cursor-pointer"
            >
              <p className="text-[16px] md:text-[24px] font-raleway font-bold text-[#060606] mb-[8px]">
                01
              </p>
              <hr className="border-[1px] border-[#E5E5E5] w-full md:w-full " />

              <img
                alt=""
                className="h-[48px] w-[48px] lg:h-[64px] lg:w-[64px] my-[1rem] ml-2"
                src={sippaint}
              />

              <p className="text-[16px] md:text-[18px] xl:text-[1.5rem] font-raleway font-bold text-[#060606] py-2">
                Sip & Paint
              </p>
              <p className="text-[13px] md:text-[17px] xl:text-[1.2rem] font-raleway font-normal text-[#060606] my-2">
                Unleash your creativity with a mixology session followed by a
                completely guided art session.
              </p>
            </div>

            <div
              onClick={() => showModalHandler("Neon Paint Party")}
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="card2 bg-[#F6F6F6] p-[8px] sm:p-[16px] lg:p-[24px] cursor-pointer"
            >
              <p className="text-[16px] md:text-[24px] font-raleway font-bold text-[#060606] py-1">
                02
              </p>
              <hr className="border-[1px] border-[#E5E5E5] w-full md:w-full" />
              <img
                alt=""
                className="h-[48px] w-[48px] lg:h-[64px] lg:w-[64px] my-[1rem] ml-2"
                src={neonPaint}
              />

              <p className="text-[16px] md:text-[18px] xl:text-[1.5rem] font-raleway font-bold text-[#060606] py-2">
                Neon Paint Party
              </p>
              <p className="text-[13px] md:text-[17px] xl:text-[1.2rem]  font-raleway font-normal text-[#060606] my-2">
                The first in hyderabad, experience the magic of making a glow in
                the dark painting.
              </p>
            </div>

            <div
              onClick={() => showModalHandler("Private Events", PRIVATE_EVENTS)}
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="card5 bg-[#F6F6F6] p-[8px] sm:p-[16px] lg:p-[24px] cursor-pointer"
            >
              <p className="text-[16px] md:text-[24px] font-raleway font-bold text-[#060606] py-1">
                03
              </p>
              <hr className="border-[1px] border-[#E5E5E5] w-full md:w-full " />

              <img
                alt=""
                className="h-[48px] w-[49px] lg:h-[64px] lg:w-[65px] my-[1rem] ml-2"
                src={privateEvent}
              />

              <p className="text-[16px] md:text-[18px] xl:text-[1.5rem] font-raleway font-bold text-[#060606] py-2">
                Private Events
              </p>
              <p className="text-[13px] md:text-[17px] xl:text-[1.2rem]  font-raleway font-normal text-[#060606] my-2">
                We cater fun experiences for birthdays, kitty parties, bridal
                showers, get togethers,...etc.
              </p>
            </div>

            <div
              onClick={() => showModalHandler("Corporate Events")}
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="card3 bg-[#F6F6F6] p-[8px] sm:p-[16px] lg:p-[24px] cursor-pointer"
            >
              <p className="text-[16px] md:text-[24px] font-raleway font-bold text-[#060606] py-1">
                04
              </p>
              <hr className="border-[1px] border-[#E5E5E5] w-full md:w-full" />

              <img
                alt=""
                className="h-[48px] w-[48px] lg:h-[64px] lg:w-[64px] my-[1rem] ml-2"
                src={therapeuticArt}
              />

              <p className="text-[16px] md:text-[18px] xl:text-[1.5rem] font-raleway font-bold text-[#060606] py-2">
                Corporate Events
              </p>
              <p className="text-[13px] md:text-[17px] xl:text-[1.2rem]  font-raleway font-normal text-[#060606] my-2">
                We specialise in unwinding your employees on team outings,
                special occasions or employee engagement programs.
              </p>
            </div>

            <div
              onClick={() => showModalHandler("Fabric Painting Orders")}
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="card4 bg-[#F6F6F6] p-[8px] sm:p-[16px] lg:p-[24px] cursor-pointer"
            >
              <p className="text-[16px] md:text-[24px] font-raleway font-bold text-[#060606] py-1">
                05
              </p>
              <hr className="border-[1px] border-[#E5E5E5] w-full md:w-full " />
              <img
                alt=""
                className="h-[48px] w-[48px] lg:h-[64px] lg:w-[64px] my-[1rem] ml-2"
                src={fabricPainting}
              />

              <p className="text-[16px] md:text-[18px] xl:text-[1.5rem] font-raleway font-bold text-[#060606] py-2">
                Fabric Painting Orders
              </p>
              <p className="text-[13px] md:text-[17px] xl:text-[1.2rem]  font-raleway font-normal text-[#060606] my-2">
                We paint your favourite designs onto sarees, kurtas, scarves
                etc. We specialise in florals, traditional designs,
              </p>
            </div>

            <div
              onClick={() => showModalHandler("Commissioned Art/Orders")}
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="card6 bg-[#F6F6F6] p-[8px] sm:p-[16px] lg:p-[24px] cursor-pointer"
            >
              <p className="text-[16px] md:text-[24px] font-raleway font-bold text-[#060606] py-1">
                06
              </p>
              <hr className="border-[1px] border-[#E5E5E5] w-full md:w-full " />

              <img
                alt=""
                className="h-[48px] w-[48px] lg:h-[64px] lg:w-[64px] my-[1rem] ml-2"
                src={commissionedArt}
              />
              <p className="text-[16px] md:text-[18px] xl:text-[1.5rem] font-raleway font-bold text-[#060606] py-2">
                Commissioned Art/Orders
              </p>
              <p className="text-[13px] md:text-[17px] xl:text-[1.2rem]  font-raleway font-normal text-[#060606] my-2">
                We take orders for portraits, landscapes & anything you want a
                painting of.
              </p>
            </div>
          </div>
        </div>

        {/* group images */}
        <div className="group  mx-0 md:mx-0 md:p-0 ">
          <h2 className="mt-5 text-center text-[32px] md:text-[80px] font-raleway  py-7 text-[#060606] tracking-wider italic font-extrabold ">
            “Gallery”
          </h2>
          {/* {
            selectedImage.image !== "" &&  (
              <div  style={{
                width:"100%",
                height:"100vh",
                background:"black",
                
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                overflow:"hidden",
                position:"absolute",
               
              }}>

              <img src={selectedImage.image} className="w-auto max-h-[80%] max-w-[80%]" />

              </div>
            ) 
          }  */}

          <Swiper
            cssMode={true}
            navigation={true}
            pagination={true}
            mousewheel={true}
            keyboard={true}
            spaceBetween={30}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            classNam
            e="mySwiper "
            className="mt-2 sm:mt-10 sm:pr-5 pl-2 sm:pl-7"
          >
            <SwiperSlide className="w-full p-5">
              <Masonry
                id="gallery"
                columns={4}
                spacing={1}
                defaultColumns={4}
                defaultSpacing={1}
                className=""
              >
                {pageOneImagesList.map((item, index) => (
                  <a
                    href={item}
                    data-pswp-width="1875"
                    data-pswp-height="1800"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={item} alt="" className="h-auto max-w-full " />
                  </a>
                ))}
              </Masonry>
            </SwiperSlide>
            <SwiperSlide className="w-full p-5">
              <Masonry
                id="gallery"
                columns={4}
                spacing={1}
                defaultColumns={4}
                defaultSpacing={1}
                className=""
              >
                {pageTwoImagesList.map((item, index) => (
                  <a
                    href={item}
                    data-pswp-width="1875"
                    data-pswp-height="1800"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={item} alt="" className="h-auto max-w-full " />
                  </a>
                ))}
              </Masonry>
            </SwiperSlide>
            <SwiperSlide className="w-full p-5">
              <Masonry
                id="gallery"
                columns={4}
                spacing={1}
                defaultColumns={4}
                defaultSpacing={1}
                className=""
              >
                {pageThreeImagesList.map((item, index) => (
                  <a
                    href={item}
                    data-pswp-width="1875"
                    data-pswp-height="1800"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={item} alt="" className="h-auto max-w-full " />
                  </a>
                ))}
              </Masonry>
            </SwiperSlide>

            <SwiperSlide className="w-full p-5">
              <Masonry
                id="gallery"
                columns={4}
                spacing={1}
                defaultColumns={4}
                defaultSpacing={1}
              >
                {imagesList.map((item, index) => (
                  <a
                    key={index}
                    href={item}
                    data-pswp-width="1875"
                    data-pswp-height="1800"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={item} alt="" className="h-full max-w-full " />
                  </a>
                ))}
              </Masonry>
            </SwiperSlide>
          </Swiper>
        </div>

        <div>
          {/* <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry>
            <div className="">
              <img className="h-auto max-w-full " src={grp10} alt="" /></div>
              <div> <img className="h-auto max-w-full " src={grp2} alt="" /></div>
              <div><img className="h-auto max-w-full " src={grp3} alt="" /></div>
            
          </Masonry>
        </ResponsiveMasonry> */}
        </div>

        {/* accordian container */}
        <div className="faq mx-1 md:mx-6 flex flex-col items-center mb-20">
          <h2 className="text-center text-[32px] md:text-[80px] font-raleway py-7 text-[#060606] tracking-wider italic font-extrabold">
            “F.A.Q”
          </h2>

          <div className="accordian  w-full flex flex-col gap-4 font-raleway">
            <div
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="accordian-1 w-full cursor-pointer mx-auto p-3 md:p-[40px] bg-[#FFFFFF] rounded-xl my-2 "
              onClick={() => setAccordian1(!accordian1)}
            >
              <div className="container-1 w-full flex justify-between items-center">
                <p className="text-[#060606] text-[16px] w-[90%] sm:w-3/4 md:text-[22px] font-semibold font-raleway py-2 md:py-2">
                  How do I book tickets for your art workshops?
                </p>
                {accordian1 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10100)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#61D4FB"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M43.4395 30.8799L48.5595 35.9999L43.4395 41.1199"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10100"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10100"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10100"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10093)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#0074BE"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M51.1191 33.4399L45.9991 38.5599L40.8791 33.4399"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10093"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10093"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10093"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                )}
              </div>

              {accordian1 ? (
                ""
              ) : (
                <div className={`container-2 hidden=${accordian1}`}>
                  <p className="text-[14px] font-[400] md:text-[22px] font-raleway mr-4 my-2 text-[#808080] ">
                    To book tickets for our art events, simply visit our website
                    and navigate to the Events section. From there, you can
                    select the desired event and follow the prompts to complete
                    the ticket booking process.
                  </p>
                </div>
              )}
            </div>

            {/* <div
              style={{ boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.15)" }}
              className="accordian-2 w-full  cursor-pointer mx-auto p-3 md:p-[40px] bg-[#FFFFFF] rounded-xl my-2"
              onClick={() => setAccordian2(!accordian2)}
            >
              <div className="container-2 flex justify-between items-center">
                <p className="text-[#060606] text-[16px] w-3/4 md:text-[22px] font-semibold font-raleway py-2 md:py-2">
                  What payment methods do you accept for ticket bookings?
                </p>
                {accordian2 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10100)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#61D4FB"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M43.4395 30.8799L48.5595 35.9999L43.4395 41.1199"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10100"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10100"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10100"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10093)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#0074BE"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M51.1191 33.4399L45.9991 38.5599L40.8791 33.4399"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10093"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10093"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10093"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                )}
              </div>

              {accordian2 ? (
                ""
              ) : (
                <div className={`container-2 hidden=${accordian1}`}>
                  <p className="text-[16px] font-[400] md:text-[22px] font-raleway mr-4 my-2 text-[#808080]">
                    To book tickets for our art events, simply visit our website
                    and navigate to the Events section. From there, you can
                    select the desired event and follow the prompts to complete
                    the ticket booking process.
                  </p>
                </div>
              )}
            </div>  */}

            <div
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="accordian-3 w-full  cursor-pointer mx-auto p-4 md:p-[40px] bg-[#FFFFFF] rounded-xl my-2"
              onClick={() => setAccordian3(!accordian3)}
            >
              <div className="container-1 flex justify-between items-center ">
                <p className="text-[#060606] text-[16px] w-[90%] sm:w-3/4 md:text-[22px] font-semibold font-raleway py-2">
                  Are tickets refundable if I am unable to attend the event?
                </p>
                {accordian3 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10100)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#61D4FB"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M43.4395 30.8799L48.5595 35.9999L43.4395 41.1199"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10100"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10100"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10100"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10093)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#0074BE"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M51.1191 33.4399L45.9991 38.5599L40.8791 33.4399"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10093"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10093"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10093"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                )}
              </div>

              {accordian3 ? (
                ""
              ) : (
                <div className={`container-2 hidden=${accordian1}`}>
                  <p className="text-[14px] font-[400] md:text-[22px] font-raleway mr-4 my-2 text-[#808080]">
                    Tickets once purchased are not refundable as the material
                    for the participant will be procured. The ticket is
                    transferable to another event of the choice of the
                    participant. The screenshot of the payment is mandatory.
                  </p>
                </div>
              )}
            </div>

            <div
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="accordian-4 w-full  cursor-pointer mx-auto p-4  md:p-[40px] bg-[#FFFFFF] rounded-xl my-2"
              onClick={() => setAccordian4(!accordian4)}
            >
              <div className="container-1 flex justify-between items-center ">
                <p className="text-[#060606] text-[16px] w-[90%] sm:w-3/4 md:text-[22px] font-semibold font-raleway py-2">
                  What art mediums do you offer?
                </p>
                {accordian4 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10100)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#61D4FB"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M43.4395 30.8799L48.5595 35.9999L43.4395 41.1199"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10100"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10100"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10100"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10093)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#0074BE"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M51.1191 33.4399L45.9991 38.5599L40.8791 33.4399"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10093"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10093"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10093"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                )}
              </div>

              {accordian4 ? (
                ""
              ) : (
                <div className={`container-2 hidden=${accordian1}`}>
                  <p className="text-[14px] font-[400] md:text-[22px] font-raleway mr-4 my-2 text-[#808080]">
                    We at flodesign offer - acrylic painting, fluid art, fabric
                    painting, name board making, etc
                  </p>
                </div>
              )}
            </div>

            <div
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="accordian-5 w-full  cursor-pointer mx-auto p-4 md:p-[40px] bg-[#FFFFFF] rounded-xl my-2"
              onClick={() => setAccordian5(!accordian5)}
            >
              <div className="container-1 flex justify-between items-center ">
                <p className="text-[#060606] text-[16px] w-[90%] sm:w-3/4 md:text-[22px] font-semibold font-raleway py-2">
                  Is there an age restriction for attending your art events?
                </p>
                {accordian5 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10100)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#61D4FB"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M43.4395 30.8799L48.5595 35.9999L43.4395 41.1199"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10100"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10100"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10100"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10093)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#0074BE"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M51.1191 33.4399L45.9991 38.5599L40.8791 33.4399"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10093"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10093"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10093"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                )}
              </div>

              {accordian5 ? (
                ""
              ) : (
                <div className={`container-2 hidden=${accordian1}`}>
                  <p className="text-[14px] font-[400] md:text-[22px] font-raleway mr-4 my-2 text-[#808080]">
                    There is no age restriction as we are all ages friendly,
                    beginner friendly and welcome anyone with the enthusiasm to
                    learn and explore art.
                  </p>
                </div>
              )}
            </div>

            <div
              style={{ boxShadow: "0px 0px 15px 0px #D8D8D8" }}
              className="accordian-6 w-full  cursor-pointer mx-auto  p-4 md:p-[40px] bg-[#FFFFFF] rounded-xl my-2"
              onClick={() => setAccordian6(!accordian6)}
            >
              <div className="container-1 flex justify-between items-center ">
                <p className="text-[#060606] text-[16px] w-[90%] sm:w-3/4 md:text-[22px] font-semibold font-raleway py-2">
                  What is the duration of the workshops?
                </p>
                {accordian6 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10100)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#61D4FB"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M43.4395 30.8799L48.5595 35.9999L43.4395 41.1199"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10100"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10100"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10100"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="92"
                    height="92"
                    viewBox="0 0 92 92"
                    fill="none"
                  >
                    <g filter="url(#filter0_d_682_10093)">
                      <rect
                        x="30"
                        y="20"
                        width="32"
                        height="32"
                        rx="16"
                        fill="#0074BE"
                        shape-rendering="crispEdges"
                      />
                      <path
                        d="M51.1191 33.4399L45.9991 38.5599L40.8791 33.4399"
                        stroke="#F6F6F6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_682_10093"
                        x="0"
                        y="0"
                        width="92"
                        height="92"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                      >
                        <feFlood
                          flood-opacity="0"
                          result="BackgroundImageFix"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="10" />
                        <feGaussianBlur stdDeviation="15" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_682_10093"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_682_10093"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                )}
              </div>

              {accordian6 ? (
                ""
              ) : (
                <div className={`container-2 hidden=${accordian1}`}>
                  <p className="text-[14px] font-[400] md:text-[22px] font-raleway mr-4 my-2 text-[#808080]">
                    Duration of the workshop typically takes about 1 and half
                    hours to 2 hours depending on the age group and number of
                    participants.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <WorkshopModal setShowModal={setShowModal} headerName={headerName} imgArr={imgArr}/>
      )}
    </div>
  );
};

export default About;
