import React from 'react';
import { css } from '@emotion/react';
import { RingLoader} from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const Loader = ({ loading }) => {
  return (
    <div className={`fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-opacity-50`}>
      
        <RingLoader color="#36D7B7" loading css={override} size={150} />
    
    </div>
  );
};

export default Loader;
