import React, { useEffect } from 'react'
import quoteimg from '../assets/qoute.png'
import desktopquoteimg from '../assets/desktopqoute.png'
import Footer from './Footer'
import { useNavigate } from 'react-router-dom'
import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Input,
    Textarea,
  } from "@material-tailwind/react";

const Shopping = () => {

    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
 
    const handleOpen = () => setOpen(!open);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
      }, []);

    return (
        <div className='shoping-container'>
            <div className="card-container px-4 md:px-14 py-10 pb-14 ">

               

                <div className="image-form-container md:flex md:justify-center md:gap-20">

                    <img src={quoteimg} alt="" className='py-3 h-[350px] w-full md:hidden' />
                    <img src={desktopquoteimg} alt="" className='py-3  w-full h-[546px] hidden md:block' />
                    <Button className='bg-red' onClick={handleOpen}>Message Dialog</Button>
                    <div className="form-container">
                        <h1 className='my-2 text-[18px] md:text-[24px] font-[700] font-raleway tracking-[1.88px] md:tracking-[2.64px]'>Title</h1>
                        <p className='my-2 md:my-4 text-[12px] md:text-[20px] font-[700] font-raleway tracking-[1.32px]  md:tracking-[2.2px]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis condimentum magna quis
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit. Duis condimentum magna quis Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis </p>
  
                        <p className='my-2 text-[12px] font-[700] font-raleway tracking-[1.32px] md:hidden'>Enter Your Details</p>
                        <form action="">

                            <input type="text" placeholder='Name' className='my-2 w-full  rounded-[3px] shadow-custom  border-[1px] border-[#808080] p-[16px] text-[12px] font-[400] font-raleway  tracking-[1.32px]  md:tracking-[2.2px] ' />
                            <input type="text" placeholder='Email' className='my-2 w-full  rounded-[3px] shadow-custom  border-[1px] border-[#808080] p-[16px] text-[12px] font-[400] font-raleway  tracking-[1.32px]  md:tracking-[2.2px]' />
                            <input type="text" placeholder='Mobile Number' className='my-2 w-full  rounded-[3px] shadow-custom  border-[1px] border-[#808080] p-[16px] text-[12px] font-[400] font-raleway  tracking-[1.32px]  md:tracking-[2.2px]' />

                        </form>

                        <button className='w-full py-[12px] px-[24px] bg-[#61D4FB] shadow-custom my-3 text-[12px] md:text-[24px] font-raleway font-[700]  tracking-[1.32px] md:tracking-[2.64px]'>Submit</button>
                    </div>
                </div>
                

            </div>

         
        </div>
    )
}

export default Shopping